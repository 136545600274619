import React, { useEffect, useState } from 'react';
import Sidebar from '../../component/Sidebar';
import Topbar from '../../component/Topbar';
import { countryApi, logoutApi, mimicBackApi } from '../../redux/ApiActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from '../../component/CardComponent';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import homeIcon from '../../images/homeIcon.svg';
import heartIcon from '../../images/heartIcon.svg';
import starIcon from '../../images/starIcon.svg';
import verifyIcon from '../../images/verifyedIcon.svg';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';

// Custom Css
import '../../css/style.css';
import '../../css/custom.css';
import BarGraph from './BarGraph';
import { Button, ButtonGroup } from '@mui/material';
import axios from 'axios';
import config from '../../redux/config';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state?.apiReducer?.loginData);
    const userRoles = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0]);
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken);

    const [agentInsightsData, setAgentInsightsData] = useState('');
    const [barGraphData, setBarGraphData] = useState('');
    const [barGraphTabFlag, setBarGraphTabFlag] = useState('High');
    const [pieChartCreditData, setPieChartCreditData] = useState('');

    useEffect(() => {
        if (userRoles != 'SuperAdministrator') {
            getBarGraphData();
            getCreditData();
            getAgentInsightsApiCall();
        }
    }, [userRoles]); // Added userRoles as dependency

    useEffect(() => {
        if (userRoles === 'SuperAdministrator') {
            dispatch(mimicBackApi(data));
        }
    }, [userRoles, data, dispatch]); // Added dependencies

    const getAgentInsightsApiCall = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/Analytic/AgentInsights`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.Status === 'OK') {
                setAgentInsightsData(response?.data);
            } else if (response.data.Status === 'ERROR') {
                // Handle error if needed
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logoutApi());
                navigate('/');
            }
        }
    };


    //COUNTRY API CALL
    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });


    }, [])
    const getBarGraphData = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/Analytic/LeadsByCities`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.Status === 'OK') {
                setBarGraphData(response?.data);
            } else if (response.data.Status === 'ERROR') {
                // Handle error if needed
            }
        } catch (error) {
            // Handle error if needed
        }
    };

    const getCreditData = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/Analytic/CreditUsage`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.Status === 'OK') {
                setPieChartCreditData(response?.data);
            } else if (response.data.Status === 'ERROR') {
                // Handle error if needed
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {

                dispatch(logoutApi())
                navigate('/')

            }

        }
    };

    const onClickBarGraphHigh = () => {
        setBarGraphTabFlag('High');
    };

    const onClickBarGraphLow = () => {
        setBarGraphTabFlag('Low');
    };

    const creditsUsage = pieChartCreditData?.Data?.CreditsUsage;
    const dataPoints = creditsUsage?.map(item => item?.Percentage);



    const pieData = {
        labels: [
            `Featured ${creditsUsage?.[0]?.TotalAmount} (${creditsUsage?.[0]?.Percentage}%)`,
            `Premium ${creditsUsage?.[1]?.TotalAmount} (${creditsUsage?.[1]?.Percentage}%)`,
            `Standard ${creditsUsage?.[2]?.TotalAmount} (${creditsUsage?.[2]?.Percentage}%)`,
            `Unused ${creditsUsage?.[3]?.TotalAmount} (${creditsUsage?.[3]?.Percentage}%)`
        ],
        datasets: [
            {
                data: dataPoints,
                backgroundColor: ['#193057', '#1F3E6A', '#DE9A09', '#FDB51F'],
                hoverBackgroundColor: ['#193057', '#1F3E6A', '#DE9A09', '#FDB51F']
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div className="App">
            <Topbar />
            <Sidebar message={'Dashboard'} />
            {(userRoles != 'SuperAdministrator') && (
                <section className="content">
                    <div className="block-header">
                        <h2>{userRoles == 'AgentAdmin'?'Company':'Agent' } Insights</h2>
                    </div>
                    <div className="row clearfix">
                        <CardComponent
                            svgIcon={<img src={homeIcon} alt="Home Icon" />}
                            icon={<KeyboardArrowUpIcon />}
                            backgroundColor={'linear-gradient(to left, #193057, #6784B5)'}
                            totalValue={agentInsightsData?.Data?.Insights?.[0]?.AvgTotalListings ?? 0}
                            avgPercentage={'+22%'}
                            title={'Total Listings'}
                        />
                        <CardComponent
                            svgIcon={<img src={verifyIcon} alt="Verify Icon" />}
                            icon={<KeyboardArrowUpIcon />}
                            backgroundColor={'linear-gradient(to left, #DE9A09, #FDB51F)'}
                            totalValue={`${agentInsightsData?.Data?.Insights?.[0]?.AvgProductExposure ?? 0}%`}
                            avgPercentage={'+15%'}
                            title={'Product Exposure'}
                        />
                        <CardComponent
                            svgIcon={<img src={starIcon} alt="Star Icon" />}
                            icon={<KeyboardArrowUpIcon />}
                            backgroundColor={'linear-gradient(to left, #193057, #6784B5)'}
                            totalValue={`${agentInsightsData?.Data?.Insights?.[0]?.AvgQualityScore ?? 0}%`}
                            avgPercentage={'+20%'}
                            title={'Quality Score'}
                        />
                        <CardComponent
                            svgIcon={<img src={heartIcon} alt="Heart Icon" />}
                            icon={<KeyboardArrowUpIcon />}
                            backgroundColor={'linear-gradient(to left, #DE9A09, #FDB51F)'}
                            totalValue={agentInsightsData?.Data?.Insights?.[0]?.AvgInterest ?? 0}
                            avgPercentage={'+32%'}
                            title={'Interest'}
                        />
                    </div>
                    <div className="block-header" style={{ marginTop: 30 }}>
                        <h2>Listings Insights</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-4">
                            <div className="box">
                                <div className="box-header">
                                    <div className="title">City</div>
                                    <div className="tabs">
                                        <ButtonGroup
                                            variant="contained"
                                            color="primary"
                                            aria-label="contained primary button group"
                                        >
                                            <Button
                                                style={{
                                                    backgroundColor: barGraphTabFlag === 'High' ? "#193057" : '#F5F6FA',
                                                    color: barGraphTabFlag === 'High' ? "#FFFFFF" : "#000",
                                                    fontSize: 11,
                                                    fontWeight: 400
                                                }}
                                                onClick={onClickBarGraphHigh}
                                            >
                                                High Per.
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: barGraphTabFlag === 'Low' ? "#2F3484" : '#F5F6FA',
                                                    color: barGraphTabFlag === 'Low' ? "#FFFFFF" : "#000",
                                                    fontSize: 11,
                                                    fontWeight: 400
                                                }}
                                                onClick={onClickBarGraphLow}
                                            >
                                                Low Per.
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className="box-content">
                                    <BarGraph data={barGraphTabFlag === 'High' ? barGraphData?.Data?.HighPerforming : barGraphData?.Data?.LowPerforming} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 pie_chart_block" style={{ marginLeft: 10, flexDirection: 'column', borderRadius: 20, height: 420, width: 430, display: 'flex', backgroundColor: '#FFFFFF' }}>
                            <div className="box-header">
                                <div className="title">{userRoles == 'AgentAdmin'?'Company':'Agent' } Credit Usage</div>
                            </div>
                            <div style={{ height: 330, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Doughnut data={pieData} options={options} />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default Dashboard;
