import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './BarGraph.css';

const BarGraph = ({ data }) => {
    return (
        <BarChart
            width={600}
            height={379}
            data={data}
            layout="vertical"
            margin={{ top: 0, right: 40, left: 40, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" />
            <Tooltip />

            <Bar dataKey="value" fill="#193057" barSize={35} />
        </BarChart>
    );
};

export default BarGraph;
