import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import residential from '../../images/residential.svg';
import commercial from '../../images/commercial.svg';
import likeIcon from '../../images/heart_blue.svg';
import likeFillIcon from '../../images/heart_red.svg';
import closeModal from '../../images/close.svg';
import rent from '../../images/rent.svg';
import sale from '../../images/sale.svg';
import folder from '../../images/folder.svg';
import lead from '../../images/lead.svg';
import deleteIcon from '../../images/delete.svg';
import dollar from '../../images/dollar-24.svg';
import bed from '../../images/bed-24.svg';
import locationIcon from '../../images/location.svg';
import bathtub from '../../images/bathtub-24.svg';
import area from '../../images/area.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Modal, TextField, Button, Autocomplete, } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Rating } from 'react-simple-star-rating';
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';


// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';

import '../../css/uicons-bold-rounded.css';


import '../../css/themes/all-themes.css';
import { scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { addLeadRequirementsApi, addNotesLeadsApi, addShortlistedPropertiesApi, countryApi, deleteLeadDocumentsApi, getByIdLeadRequirementsApi, getLeadsDetailApi, leadDocumentsListApi, leadModeApi, leadsCreateAPi, leadsUpdateAPi, locationListApi, logoutApi, matchingListingsApi, notesListLeadApi } from '../../redux/ApiActionCreator';
import axios from 'axios';
import config from '../../redux/config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Carousel from '../../component/Carousel';
import PhoneInput from 'react-phone-input-2';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const matchOptions = [
    'Shortlist',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function LeadsDetail() {


    const field1Ref = useRef();
    const field2Ref = useRef();
    const field3Ref = useRef();
    const field4Ref = useRef();

    const { t } = useTranslation();

    const [rating, setRating] = useState(0)
    // Catch Rating value
    const handleRating = (rate) => {

        setRating(rate)

    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)
    const dispatch = useDispatch()
    const theme = useTheme();
    const navigate = useNavigate()
    const [personName, setPersonName] = useState([]);
    const [residentCategory, setResidentCategory] = useState(2)
    const [rentalCategory, setRentalCategory] = useState(1)
    const [propertyTypeSelected, setPropertyTypeSelected] = useState("")
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(1);
    const [contactAccordingList, setContactAccordingList] = useState(true)
    const [shortlistAccordingList, setShortlistAccordingList] = useState(true)
    const [matchingAccordingList, setMatchingAccordingList] = useState(true)
    const [requirementAccordingList, setRequirementAccordingList] = useState(true)
    const [addressAccordingList, setAddressAccordingList] = useState(true)
    const [leadAccordingList, setLeadAccordingList] = useState(true)
    const [qualityAccordingList, setQualityAccordingList] = useState(true)
    const [financialAccordingList, setFinancialAccordingList] = useState(true)
    const [legalAccordingList, setLegalAccordingList] = useState(true)
    const [value, setValue] = React.useState('one');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [landline, setlandline] = useState('')
    const [passportId, setPassportId] = useState('')
    const [passportExpireDate, setpassportExpireDate] = useState('')
    const [poBox, setpoBox] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [timeFrameName, setTimeFrameName] = useState('')
    const [leadSource, setLeadSource] = useState('')
    const [leadChannel, setLeadChannel] = useState('')
    const [financial, setFinancial] = useState('')
    const [legalFirstName, setlegalFirstName] = useState('')
    const [legalLastName, setlegalLastName] = useState('')
    const [legalEmail, setlegalEmail] = useState('')
    const [legalContact, setlegalContact] = useState('')
    const [reference, setReference] = useState('')
    const [notes, setnotes] = useState('')
    const [leadStatus, setLeadStatus] = useState('')
    const [lastUpdateValue, setLastUpdateValue] = useState('')
    const [createdValue, setCreatedValue] = useState('')
    const [createdByFirstName, setCreatedByFirstName] = useState('')
    const [createdByLastName, setCreatedByLastName] = useState('')
    const leadMode = useSelector((state) => state?.apiReducer?.leadMode)
    const [stateIdSelected, setStateIdSelected] = useState('')
    const [cityIdSelected, setCityIdSelected] = useState('')
    const [prAddressSelected, setPrAddressSelected] = useState('')
    const [propertyDocument, setpropertyDocument] = useState('')
    const [loading, setLoading] = useState(false)
    const [firstNameError, setFirstNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [leadInfoSourceError, setleadInfoSourceError] = useState('')
    const [notesError, setnotesError] = useState('')
    const [propertyDocumentError, setpropertyDocumentError] = useState('')
    const [budgetValue, budgetSetValue] = useState([0, 5000000]);
    const [sizeValue, sizeSetValue] = useState([0, 9000]);
    const [bedroomValue, bedroomSetValue] = useState([-1, 20]);
    const [selectedChips, setSelectedChips] = useState([]);
    const [options, setOptions] = useState([]);
    const leadOfflineData = useSelector((state) => state?.apiReducer?.leadOfflineData)
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const leadsDropdownData = useSelector((state) => state?.apiReducer?.leadsDropdownData)
    const notesListLeadData = useSelector((state) => state?.apiReducer?.notesListLeadData)
    const documentsListLeadData = useSelector((state) => state?.apiReducer?.documentsListLeadData)
    const getLeadDetailsData = useSelector((state) => state?.apiReducer?.getLeadDetailsData)
    const userId = useSelector((state) => state?.apiReducer?.loginData?.Data?.userId)
    const leadCreateData = useSelector((state) => state?.apiReducer?.leadCreateData)
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const [leadByIdData, setleadByIdData] = useState([])
    const [location, setLocation] = useState({})

    const [deleteDocumentPoppup, setdeleteDocumentPoppup] = useState(false)
    const [selectDocumentId, setselectDocumentId] = useState('')
    const [shortlistDropdownAnchorEl, setShortlistDropdownAnchorEl] = React.useState(null);
    const shortlistDropdownOpen = Boolean(shortlistDropdownAnchorEl);
    const [matchingDropdownAnchorEl, setMatchingDropdownAnchorEl] = React.useState(null);
    const matchingDropdownOpen = Boolean(matchingDropdownAnchorEl);
    const [addPropertyOpen, setAddPropertyOpen] = React.useState(false);
    const [inputTypeExpiredate, setInputTypeExpiredate] = useState('text');
    const [matchingListingsData, setmatchingListingsData] = useState([])
    const [sortListData, setsortListData] = useState([])

    const selectedPropertyTypeDropdown = residentCategory == 2 && rentalCategory == 1 ? leadsDropdownData?.ResidentialRent : residentCategory == 2 && rentalCategory == 2 ? leadsDropdownData?.ResidentialSale : residentCategory == 1 && rentalCategory == 1 ? leadsDropdownData?.CommertialRent : leadsDropdownData?.CommertialSale
    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };






    const validateAndFormatDate = (dateString) => {
        // Check if the date is in dd-mm-yyyy format
        const isDDMMYYYYFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

        // If it's in dd-mm-yyyy format, convert to yyyy-mm-dd
        if (isDDMMYYYYFormat) {
            const [day, month, year] = dateString.split('-');
            const convertedDate = `${year}-${month}-${day}`;
            return convertedDate;
        }

        // If it's not in dd-mm-yyyy format, return the original date
        return dateString;
    };


    const addPropertyHandleClose = () => {
        setAddPropertyOpen(false);
    };
    const handleFocusExpireDate = (e) => {
        setInputTypeExpiredate('date');
        e.target.blur()
    };



    const shortlistDropdownHandleClose = (e) => {
        if (e == 'Add Property') {
            setAddPropertyOpen(true)
        } else if (e == 'Remove From Shortlist') {
            // alert('remove')
        }
        setShortlistDropdownAnchorEl(null);
    };


    const matchingDropdownHandleClose = (e) => {

        setMatchingDropdownAnchorEl(null);
    };

    const handleChipClick = (chip) => {
        const isSelected = selectedChips.includes(chip);

        if (isSelected) {
            setSelectedChips(selectedChips.filter((selectedChip) => selectedChip !== chip));
        } else {
            setSelectedChips([...selectedChips, chip]);
        }
    };

    const budgetHandleChange = (newValue) => {
        budgetSetValue(newValue);
    };

    const sizeHandleChange = (newValue) => {
        sizeSetValue(newValue);
    };

    const bedroomHandleChange = (newValue) => {
        bedroomSetValue(newValue);
    };



    const handleStyle = {
        backgroundColor: '#fff', // Change the thumb color
        borderColor: '#FDB51F', // Change the border color
    };

    const trackStyle = {
        backgroundColor: '#FDB51F', // Change the track color
    };

    const pageHandleChange = (event, value) => {
        setPage(value);
    };
    const fieldDefaultRefTop = useRef();
    useEffect(() => {
        scroller.scrollTo(fieldDefaultRefTop.current.id, {
            smooth: false,
            offset: -100, // Adjust as needed
        });
    }, [])

    //GET LEAD DETAIL API CALL
    useEffect(() => {
        if (leadOfflineData?.leadType != 'create' || !leadMode?.mode == 0) {

            dispatch(getLeadsDetailApi(token, leadOfflineData?.leadId))
        }

    }, [])

    // GET MATCHING LISTINGS AND SHORTLISTED PROPERTIES  
    useEffect(() => {
        matchingListApi()
        sortListApi()

    }, [value])

    useEffect(() => {
        dispatch(locationListApi(token))
    }, [])

    // GET MATCHING LIST API CALL
    const matchingListApi = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/Lead/MatchingListings?id=${leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                if (response.data.Status == 'OK') {
                    sortListApi()
                    const matchData = response?.data

                    setmatchingListingsData(matchData)


                    setLoading(false)
                    setOpen(false)

                } else if (response.data.Status == 'ERROR') {

                }

            } else {

            }
        } catch (error) {

        }
    };

    // GET SHORTLISTED PROP. API CALL
    const sortListApi = async () => {

        try {
            const response = await axios.get(`${config.baseURL}/Lead/ShortListedProperties?LeadId=${leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                if (response.data.Status == 'OK') {
                    const matchData = response?.data?.Data
                    setsortListData(matchData)

                    setLoading(false)
                    setOpen(false)

                } else if (response.data.Status == 'ERROR') {

                }

            } else {

            }
        } catch (error) {

        }
    };

    function convertDateFormat(inputDate) {
        // Split the date string into parts
        const parts = inputDate.split('-');

        // Rearrange the parts to dd-mm-yyyy format
        const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        return outputDate;
    }
    useEffect(() => {
        if (leadOfflineData?.leadType != 'create' || !leadMode?.mode == 0) {
            setFirstName(getLeadDetailsData?.Firstname == null ? '' : getLeadDetailsData?.Firstname)
            setLastName(getLeadDetailsData?.Lastname == null ? '' : getLeadDetailsData?.Lastname)
            setEmail(getLeadDetailsData?.Email == null ? '' : getLeadDetailsData?.Email)
            setContact(getLeadDetailsData?.Contactno == null ? '' : getLeadDetailsData?.Contactno)
            setlandline(getLeadDetailsData?.Landlineno == null ? '' : getLeadDetailsData?.Landlineno)
            setPassportId(getLeadDetailsData?.PassportId == null ? '' : getLeadDetailsData?.PassportId)
            setCountry(getLeadDetailsData?.CountryId == null ? '' : getLeadDetailsData?.CountryId)
            setCity(getLeadDetailsData?.City == null ? '' : getLeadDetailsData?.City)
            setRating(getLeadDetailsData?.LeadQuality == null ? '' : getLeadDetailsData?.LeadQuality)
            setAddress(getLeadDetailsData?.Address == null ? '' : getLeadDetailsData?.Address)
            setpassportExpireDate(getLeadDetailsData?.PassportexpdateString == "" ? null : convertDateFormat(getLeadDetailsData?.PassportexpdateString))
            setLeadSource(getLeadDetailsData?.Sourceid == null ? '' : getLeadDetailsData?.Sourceid)
            setLeadChannel(getLeadDetailsData?.ChannelId == null ? '' : getLeadDetailsData?.ChannelId)
            setFinancial(getLeadDetailsData?.Finance == null ? '' : getLeadDetailsData?.Finance)
            setlegalFirstName(getLeadDetailsData?.LegalRFirstName == null ? '' : getLeadDetailsData?.LegalRFirstName)
            setlegalLastName(getLeadDetailsData?.LegalRLastName == null ? '' : getLeadDetailsData?.LegalRLastName)
            setlegalEmail(getLeadDetailsData?.LegalRemail == null ? '' : getLeadDetailsData?.LegalRemail)
            setlegalContact(getLeadDetailsData?.LegalRPhone == null ? '' : getLeadDetailsData?.LegalRPhone)
            setpoBox(getLeadDetailsData?.POBox == null ? '' : getLeadDetailsData?.POBox)
            setLeadStatus(getLeadDetailsData?.LeadStatusId == null ? '' : getLeadDetailsData?.LeadStatusId)
            setLastUpdateValue(getLeadDetailsData?.Updated == null ? '' : getLeadDetailsData?.Updated)
            setCreatedValue(getLeadDetailsData?.Created == null ? '' : getLeadDetailsData?.Created)
            setReference(getLeadDetailsData?.AssignedtouserId == null ? '' : getLeadDetailsData?.AssignedtouserId)
            setCreatedByFirstName(getLeadDetailsData?.CreatedByFirstName == null ? '' : getLeadDetailsData?.CreatedByFirstName)
            setCreatedByLastName(getLeadDetailsData?.CreatedByLastName == null ? '' : getLeadDetailsData?.CreatedByLastName)

        }
        if (leadOfflineData?.leadType == 'create' || leadMode?.mode == 0) {
            setReference(userId)

        }

    }, [])

    // GET NOTES LIST API CALL
    useEffect(() => {

        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
    }, [value])

    // GET DOCUMENTS LIST API CALL
    useEffect(() => {
        const params = {
            pageNumber: page,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))
    }, [page, value])



    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });

    }, [])

    // GET BY ID LEAD REQUIREMENTS 
    useEffect(() => {
        if (leadOfflineData?.leadType != 'create') {

            getByLeadIdRequirementsApi()
        }

    }, [value])

    // GET BY ID LEAD REQUIREMENTS API
    const getByLeadIdRequirementsApi = async () => {


        try {
            const response = await axios.get(`${config.baseURL}/Lead/GetLeadRequirement?id=${leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                setLoading(false)

                if (response.data.Status == 'OK') {
                    const masterData = response?.data?.Data
                    setleadByIdData(masterData)
                    setResidentCategory(masterData?.Lead?.CategoryId == null ? 2 : masterData?.Lead?.Category?.Id)
                    setRentalCategory(masterData?.Lead?.OfferingTypeId == null ? 1 : masterData?.Lead?.OfferingTypes?.Id)
                    setPropertyTypeSelected(masterData?.Lead?.PropertyType?.Id == null ? "" : masterData?.Lead?.PropertyType?.Id)
                    budgetSetValue([masterData?.Lead?.MinBudget == null ? 0 : masterData?.Lead?.MinBudget, masterData?.Lead?.MaxBudget == null ? 5000000 : masterData?.Lead?.MaxBudget])
                    sizeSetValue([masterData?.Lead?.MinSize == null ? 0 : masterData?.Lead?.MinSize, masterData?.Lead?.MaxSize == null ? 9000 : masterData?.Lead?.MaxSize])
                    bedroomSetValue([masterData?.Lead?.MinBedrooms == null ? -1 : masterData?.Lead?.MinBedrooms, masterData?.Lead?.MaxBedrooms == null ? 20 : masterData?.Lead?.MaxBedrooms])
                    setTimeFrameName(masterData?.Lead?.TimeframeId == null ? '' : masterData?.Lead?.TimeframeId)
                    setSelectedChips(masterData?.Amenities == null ? [] : masterData?.Amenities)

                    setStateIdSelected(masterData?.Lead?.PRStateID == null ? '' : masterData?.Lead?.PRStateID)
                    setCityIdSelected(masterData?.Lead?.PRCityID == null ? '' : masterData?.Lead?.PRCityID)
                    setPrAddressSelected(masterData?.Lead?.PRAddress == null ? '' : masterData?.Lead?.PRAddress)
                    matchingListApi()
                    sortListApi()

                } else if (response.data.Status == 'ERROR') {

                }

            } else {

            }
        } catch (error) {

        }
    };





    // UPLOAD DOCUMENT API CALL
    const handleFileChange = async (e) => {
        setLoading(true)
        const file = e.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF,TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('LeadId', leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id);


            const response = await axios.post(
                `${config.baseURL}/LeadDocument/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)
            if (response.data.Status == 'OK') {
                e.target.value = null;

                e.target.dispatchEvent(new Event('input'));
                const params = {
                    pageNumber: 1,
                    LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                }
                dispatch(leadDocumentsListApi(token, params))
                    .then((response) => {
                        setLoading(false)
                        toast.success("File Uploaded Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    })

            } else {

            }

        } catch (error) {
            // Handle any errors here
            console.error('Error:', error);
        }

    };



    const validateFirstname = (value) => {
        if (value.length === 0) {
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }

    }


    const validateEmail = (value) => {
        if (value.length === 0) {
            setEmailError('Please enter email');
        }
        else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {

            setEmailError('Enter valid email');

        } else {
            setEmailError('');
        }

    }


    const validateLeadInfo = (value) => {
        if (value.length === 0) {
            setleadInfoSourceError(t('leadSourceError'));
        } else {
            setleadInfoSourceError('');
        }

    }




    // CREATE/EDIT LEAD ON CLICK SAVE & NEXT
    const opportunityNextApi = () => {
        let validate = false
        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }




        if (email == '') {
            validate = true
            setEmailError('Please enter email');
        }
        else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            validate = true
            setEmailError('Enter valid email');

        } else {
            setEmailError('');
        }




        if (leadSource == '') {
            validate = true
            setleadInfoSourceError(t('leadSourceError'));
        } else {
            setleadInfoSourceError('');
        }
        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }

            else if (!field3Ref.current.value) {
                topmostFieldRef = field3Ref;
            }
            else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field3Ref.current.value))) {

                topmostFieldRef = field3Ref;

            }
            else if (!leadSource) {
                topmostFieldRef = field4Ref;
            }

            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }

        if (validate == false) {
            setLoading(true)
            if (leadOfflineData?.leadType == 'create' && leadMode?.mode == 0) {
                const params = {
                    "Firstname": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "Contactno": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),                    
                    "Landlineno": landline == '' ? null : landline,
                    "PassportId": passportId == '' ? null : passportId,
                    "Passportexpdate": passportExpireDate == '' ? null : validateAndFormatDate(passportExpireDate),
                    "POBox": poBox == '' ? null : poBox,
                    "CountryId": country == '' ? null : country,
                    "City": city == '' ? null : city,
                    "Address": address == '' ? null : address,
                    "Sourceid": leadSource == '' ? null : leadSource,
                    "ChannelId": leadChannel == '' ? null : leadChannel,
                    "LeadQuality": rating == '' ? null : rating,
                    "Finance": financial == '' ? null : financial,
                    "LegalRFirstName": legalFirstName == '' ? null : legalFirstName,
                    "LegalRLastName": legalLastName == '' ? null : legalLastName,
                    "LegalRemail": legalEmail == '' ? null : legalEmail,
                    "LegalRPhone": legalContact === "" ? null : (legalContact.startsWith("+") ? legalContact : `+${legalContact}`),                    
                    "LeadStatusId": leadStatus == '' ? null : leadStatus,
                    "AssignedtouserId": reference == '' ? null : reference,


                }
                dispatch(leadsCreateAPi(token, params))
                    .then((response) => {

                        dispatch(leadModeApi(1))
                        const params = {
                            pageNumber: 1,
                            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                        }
                        dispatch(leadDocumentsListApi(token, params))


                        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    setValue('two')
                                }, 1000);

                            })

                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {
                const params = {
                    "Id": leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id,
                    "Firstname": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "Contactno": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),                    
                    "Landlineno": landline == '' ? null : landline,
                    "PassportId": passportId == '' ? null : passportId,
                    "Passportexpdate": passportExpireDate == '' ? null : validateAndFormatDate(passportExpireDate),
                    "POBox": poBox == '' ? null : poBox,
                    "CountryId": country == '' ? null : country,
                    "City": city == '' ? null : city,
                    "Address": address == '' ? null : address,
                    "Sourceid": leadSource == '' ? null : leadSource,
                    "ChannelId": leadChannel == '' ? null : leadChannel,
                    "LeadQuality": rating == '' ? null : rating,
                    "Finance": financial == '' ? null : financial,
                    "LegalRFirstName": legalFirstName == '' ? null : legalFirstName,
                    "LegalRLastName": legalLastName == '' ? null : legalLastName,
                    "LegalRemail": legalEmail == '' ? null : legalEmail,
                    "LegalRPhone": legalContact === "" ? null : (legalContact.startsWith("+") ? legalContact : `+${legalContact}`),                    
                    "LeadStatusId": leadStatus == '' ? null : leadStatus,
                    "AssignedtouserId": reference == '' ? null : reference
                }
                dispatch(leadsUpdateAPi(token, params))
                    .then((response) => {
                        const params = {
                            pageNumber: 1,
                            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                        }
                        dispatch(leadDocumentsListApi(token, params))


                        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    setValue('two')
                                }, 1000);

                            })



                    })
                    .catch((error) => {
                        setLoading(false)

                    });
            }

        }


    }

    // CREATE/EDIT LEAD ON CLICK SAVE
    const opportunityApi = () => {
        let validate = false
        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }


        if (email == '') {
            validate = true
            setEmailError('Please enter email');
        }
        else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            validate = true
            setEmailError('Enter valid email');

        } else {
            setEmailError('');
        }



        if (leadSource == '') {
            validate = true
            setleadInfoSourceError(t('leadSourceError'));
        } else {
            setleadInfoSourceError('');
        }

        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }

            else if (!field3Ref.current.value) {
                topmostFieldRef = field3Ref;
            }
            else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field3Ref.current.value))) {

                topmostFieldRef = field3Ref;

            }
            else if (!leadSource) {
                topmostFieldRef = field4Ref;
            }

            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }
        if (validate == false) {
            setLoading(true)
            if (leadOfflineData?.leadType == 'create' && leadMode?.mode == 0) {
                const params = {
                    "Firstname": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "Contactno": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),                    
                    "Landlineno": landline == '' ? null : landline,
                    "PassportId": passportId == '' ? null : passportId,
                    "Passportexpdate": passportExpireDate == '' ? null : validateAndFormatDate(passportExpireDate),
                    "POBox": poBox == '' ? null : poBox,
                    "CountryId": country == '' ? null : country,
                    "City": city == '' ? null : city,
                    "Address": address == '' ? null : address,
                    "Sourceid": leadSource == '' ? null : leadSource,
                    "ChannelId": leadChannel == '' ? null : leadChannel,
                    "LeadQuality": rating == '' ? null : rating,
                    "Finance": financial == '' ? null : financial,
                    "LegalRFirstName": legalFirstName == '' ? null : legalFirstName,
                    "LegalRLastName": legalLastName == '' ? null : legalLastName,
                    "LegalRemail": legalEmail == '' ? null : legalEmail,
                    "LegalRPhone": legalContact === "" ? null : (legalContact.startsWith("+") ? legalContact : `+${legalContact}`),                    
                    "LeadStatusId": leadStatus == '' ? null : leadStatus,
                    "AssignedtouserId": reference == '' ? null : reference


                }
                dispatch(leadsCreateAPi(token, params))
                    .then((response) => {
                        dispatch(leadModeApi(1))
                        const params = {
                            pageNumber: 1,
                            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                        }
                        dispatch(leadDocumentsListApi(token, params))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    navigate('/LeadsList')
                                }, 1000);

                            })


                        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))


                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {
                const params = {
                    "Id": leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id,
                    "Firstname": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "Contactno": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),                    
                    "Landlineno": landline == '' ? null : landline,
                    "PassportId": passportId == '' ? null : passportId,
                    "Passportexpdate": passportExpireDate == '' ? null : validateAndFormatDate(passportExpireDate),
                    "POBox": poBox == '' ? null : poBox,
                    "CountryId": country == '' ? null : country,
                    "City": city == '' ? null : city,
                    "Address": address == '' ? null : address,
                    "Sourceid": leadSource == '' ? null : leadSource,
                    "ChannelId": leadChannel == '' ? null : leadChannel,
                    "LeadQuality": rating == '' ? null : rating,
                    "Finance": financial == '' ? null : financial,
                    "LegalRFirstName": legalFirstName == '' ? null : legalFirstName,
                    "LegalRLastName": legalLastName == '' ? null : legalLastName,
                    "LegalRemail": legalEmail == '' ? null : legalEmail,
                    "LegalRPhone": legalContact === "" ? null : (legalContact.startsWith("+") ? legalContact : `+${legalContact}`),                    
                    "LeadStatusId": leadStatus == '' ? null : leadStatus,
                    "AssignedtouserId": reference == '' ? null : reference



                }
                dispatch(leadsUpdateAPi(token, params))
                    .then((response) => {
                        const params = {
                            pageNumber: 1,
                            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                        }
                        dispatch(leadDocumentsListApi(token, params))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    navigate('/LeadsList')
                                }, 1000);

                            })

                        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))


                    })
                    .catch((error) => {
                        setLoading(false)

                    });
            }

        }


    }



    const openCloseContactAccordingList = () => {
        setContactAccordingList(!contactAccordingList)
    }

    const openCloseShortlistAccordingList = () => {
        setShortlistAccordingList(!shortlistAccordingList)
    }

    const openCloseMatchingAccordingList = () => {
        setMatchingAccordingList(!matchingAccordingList)
    }

    const openCloseRequirementAccordingList = () => {
        setRequirementAccordingList(!requirementAccordingList)
    }

    const openCloseAddressAccordingList = () => {
        setAddressAccordingList(!addressAccordingList)
    }



    const openCloseLeadAccordingList = () => {
        setLeadAccordingList(!leadAccordingList)
    }

    const openCloseQualityAccordingList = () => {
        setQualityAccordingList(!qualityAccordingList)
    }

    const openCloseFinancialAccordingList = () => {
        setFinancialAccordingList(!financialAccordingList)
    }

    const openCloseLegalAccordingList = () => {
        setLegalAccordingList(!legalAccordingList)
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    const handleChanges = (event, newValue) => { setValue(newValue); };



    // ADD/CREATE NOTES API CALL
    const addLeadNotes = () => {

        let validate = false
        if (notes == '') {
            validate = true
            setnotesError(t('NotesRequired'));
        } else {
            setnotesError('');
        }

        if (validate == false) {
            const params = {
                LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id,
                Note: notes,
            }
            dispatch(addNotesLeadsApi(token, params))
                .then((response) => {
                    setnotes('')
                    dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
                        .then((response) => {
                            toast.success("Record Saved Successfully", {
                                position: 'top-right',
                                autoClose: 1000, // Adjust the duration as needed
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });

                        })

                })
                .catch((error) => {
                    setLoading(false)

                });
        }

    }

    const handleClosePoppup = () => {
        setdeleteDocumentPoppup(false)
    }

    const handleOpenPoppup = (id) => {
        setdeleteDocumentPoppup(true)
        setselectDocumentId(id)
    }

    // DELETE DOCUMENT API CALL
    const onClickDeleteDocument = () => {
        dispatch(deleteLeadDocumentsApi(token, selectDocumentId))
            .then((response) => {
                setPage(1)
                const params = {
                    pageNumber: 1,
                    LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

                }
                dispatch(leadDocumentsListApi(token, params))
                    .then((response) => {
                        setdeleteDocumentPoppup(false)
                    })
                    .catch((error) => {
                        setLoading(false)

                    });


            })
            .catch((error) => {
                setLoading(false)

            });
    }

    // ADD LEAD REQUIREMENT API CALL
    const onClickAddLeadRequirement = () => {
        setLoading(true)
        const params = {
            "LeadId": leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id,
            "CategoryId": residentCategory == '' ? null : residentCategory,
            "OfferingTypeId": rentalCategory == '' ? null : rentalCategory,
            "LocationId": location == '' ? null : location,
            "PropertyTypeId": propertyTypeSelected == '' ? null : propertyTypeSelected,
            "MinBudget": budgetValue[0],
            "MaxBudget": budgetValue[1],
            "MinSize": sizeValue[0],
            "MaxSize": sizeValue[1],
            "MinBedrooms": bedroomValue[0],
            "MaxBedrooms": bedroomValue[1],
            "TimeframeId": timeFrameName == '' ? null : timeFrameName,
            "Amenities": selectedChips == [] ? null : selectedChips,
            "PRAddress": prAddressSelected == '' ? null : prAddressSelected,
            "PRCityID": cityIdSelected == '' ? null : cityIdSelected,
            "PRStateID": stateIdSelected == '' ? null : stateIdSelected

        }
        dispatch(addLeadRequirementsApi(token, params))
            .then((response) => {

                getByLeadIdRequirementsApi()

            })
            .catch((error) => {
                setLoading(false)

            });
    }

    const onClickPropertyBack = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))

        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
        setValue('one')
    }
    const onClickPropertySave = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))


        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
            .then((response) => {
                toast.success("Record Saved Successfully", {
                    position: 'top-right',
                    autoClose: 1000, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setTimeout(() => {
                    setLoading(false)
                    navigate('/LeadsList')
                }, 1000);

            })



    }
    const onClickPropertyNext = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))


        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
            .then((response) => {
                toast.success("Record Saved Successfully", {
                    position: 'top-right',
                    autoClose: 1000, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setTimeout(() => {
                    setLoading(false)
                    setValue('three')
                }, 1000);

            })

    }

    const onClickNotesBack = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))


        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
        setValue('two')
    }
    const onClickNotesNext = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))


        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))


        setValue('four')
    }

    const onClickDocumentBack = () => {
        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))


        dispatch(notesListLeadApi(token, leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id))
        setValue('three')
    }

    const onClickDocumentNext = (event) => {
        const scrollPosition = window.scrollY;
        event.preventDefault();
        window.scrollTo(0, scrollPosition);

        const params = {
            pageNumber: 1,
            LeadId: leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id

        }
        dispatch(leadDocumentsListApi(token, params))



        navigate('/LeadsList')





    }

    // ADD PROPERTY TO SHORTLISTED API CALL 
    const onClickAddShortlistedPropertiesApi = async (id) => {
        setLoading(true)

        try {
            const response = await axios.get(`${config.baseURL}/Lead/AddShortListedProperties?LeadId=${leadOfflineData?.leadType == 'create' ? leadCreateData?.LeadId : getLeadDetailsData?.Id}&PropertyId=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status == 200) {
                if (response.data.Status == 'OK') {
                    matchingListApi()


                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)
                }

            } else {

            }
        } catch (error) {

        }
    };


    // GET LOCATION AUTOCOMPLETE DROPDOWN API CALL
    const handleLocationSearch = async (inputValue) => {
        if (inputValue.length == 0) {
            setOptions([]); // Clear the options when the input is empty
        }
        else if (inputValue.length > 0) {
            try {
                const response = await axios.post(`${config.baseURL}/Location/BahrainSearch?input=${inputValue}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response?.data?.Data; // Adjust this based on your API response structure

                setOptions(data); // Update the options state with the response data
            } catch (error) {
                console.error('Error fetching location data', error);
            }
        }
    };

    const handleSelect = async (event, value) => {
        setLocation(value)
        setStateIdSelected(value?.StateId)
        setCityIdSelected(value?.CityId)
        setPrAddressSelected(value?.Name)
        // You can perform any further actions with the selected value here
    };

    // DOWNLOAD DOCUMENT API CALL
    const handleDownload = (item) => {
        // Specify the URL of the file you want to download
        const fileUrl = item?.FileUrl;

        // Specify the desired filename
        const fileName = item?.Name;

        // Construct the full URL with the filename
        const urlWithFileName = `${config.baseURL}${fileUrl}?filename=${fileName}`;

        // Open a new tab and set its location to the constructed URL
        window.open(urlWithFileName, '_blank');

    };

    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={deleteDocumentPoppup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosePoppup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClosePoppup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClosePoppup}>Cancel</Button>
                    <Button onClick={() => onClickDeleteDocument()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'Leads'} />

            <section class="content">
                <div
                    id="fieldDefaultTop" ref={fieldDefaultRefTop} class="block-header">
                    <h2>{t('leadsDetails')}</h2>

                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block">
                            <Tabs
                                value={value}
                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={t('opportunity')} wrapped />
                                <Tab className='tabs_title' value="two" label={t('properties')} disabled={leadMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="three" label={t('notes')} disabled={leadMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="four" label={t('documents')} disabled={leadMode?.mode == 0 ? true : false} />
                            </Tabs>
                        </div>

                        <div class="tab_content_block">
                            {/* <!-- Tab panes --> */}
                            {
                                value == 'one' ?
                                    <div class="row clearfix">
                                        <div class="col-sm-9">
                                            <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_3">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseContactAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_3">
                                                                <span>{t('contactInformation')}</span>
                                                                <i className={contactAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {contactAccordingList ?
                                                        <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div className="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('firstName')} placeholder={t('firstName') + '*'} ref={field1Ref} id="field1" value={firstName} onChange={(e) => { setFirstName(e.target.value); validateFirstname(e.target.value) }} />
                                                                                        {
                                                                                            firstNameError && (
                                                                                                <span class="validation_notes">{firstNameError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('lastName')} placeholder={t('lastName')} ref={field2Ref} id="field2" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('email')} placeholder={t('email') + '*'} ref={field3Ref} id="field3" value={email} onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value) }} />
                                                                                        {
                                                                                            emailError && (
                                                                                                <span class="validation_notes">{emailError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">

                                                                                <div class="form-group phone-input">
                                                                                    <Tooltip title={'Contact No.'} arrow className="tooltip_notes">
                                                                                        <div>
                                                                                            <PhoneInput
                                                                                                className="contact_form_control"
                                                                                                placeholder='Contact No.'
                                                                                                country={"bh"}
                                                                                                enableSearch={true}
                                                                                                value={contact}
                                                                                                onChange={(e) => { setContact(e) }}
                                                                                            />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" maxLength={15} title={t('landlineNo')} placeholder={t('landlineNo')} value={landline} onChange={(e) => setlandline(e.target.value)} onKeyPress={handleKeyPress} />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control right_space" title={t('passport/Id')} placeholder={t('passport/Id')} value={passportId} onChange={(e) => { setPassportId(e.target.value); }} />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-12">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type={inputTypeExpiredate}
                                                                                            title={t('passportExpiryDate')}
                                                                                            onFocus={(e) => handleFocusExpireDate(e)} max="9999-12-31" class="form-control" placeholder={t('passportExpiryDate')} value={passportExpireDate}
                                                                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                            onChange={(e) => { setpassportExpireDate(e.target.value); }} />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_4" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_4">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseAddressAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_4">
                                                                <span>{t('address')}</span>
                                                                <i className={addressAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {addressAccordingList ?
                                                        <div id="collapseOne_4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_4">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('pOBox')} placeholder={t('pOBox')} value={poBox} onChange={(e) => { setpoBox(e.target.value); }} />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            title={t('country')}
                                                                                            displayEmpty
                                                                                            value={country}
                                                                                            onChange={(e) => { setCountry(e.target.value); }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('country')}</em>
                                                                                            </MenuItem>
                                                                                            {leadsDropdownData?.Countries?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>

                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <textarea rows="4" class="form-control no-resize" title={t('city')} placeholder={t('city')} value={city} onChange={(e) => { setCity(e.target.value); }}></textarea>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <textarea rows="4" class="form-control no-resize" title={t('address')} placeholder={t('address')} value={address} onChange={(e) => { setAddress(e.target.value); }} ></textarea>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>

                                            <div class="panel-group full-body" id="accordion_6" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_6">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseLeadAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_6">
                                                                <span>{t('leadInformation')}</span>
                                                                <i className={leadAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {leadAccordingList ?
                                                        <div id="collapseOne_6" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_6">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div ref={field4Ref} id="field4" class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            displayEmpty
                                                                                            value={leadSource}
                                                                                            onChange={(e) => { setLeadSource(e.target.value); validateLeadInfo(e.target.value) }}
                                                                                            input={<OutlinedInput />}
                                                                                            title={t('source')}
                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('source') + '*'}</em>
                                                                                            </MenuItem>
                                                                                            {leadsDropdownData?.LeadSources?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            leadInfoSourceError && (
                                                                                                <span class="validation_notes">{leadInfoSourceError}</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            title={t('channel')}
                                                                                            displayEmpty
                                                                                            value={leadChannel}
                                                                                            onChange={(e) => { setLeadChannel(e.target.value); }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('channel')}</em>
                                                                                            </MenuItem>
                                                                                            {leadsDropdownData?.Channels?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>

                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_5" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_5">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseQualityAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_5">
                                                                <span>{t('leadQuality')}</span>
                                                                <i className={qualityAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {qualityAccordingList ?
                                                        <div id="collapseOne_5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_5">
                                                            <div class="panel-body">
                                                                <div class="card">

                                                                    <Box
                                                                        sx={{
                                                                            '& > legend': { mt: 2 },
                                                                        }}
                                                                    >

                                                                        <Rating
                                                                            onClick={handleRating}
                                                                            onPointerEnter={onPointerEnter}
                                                                            onPointerLeave={onPointerLeave}
                                                                            onPointerMove={onPointerMove}
                                                                            initialValue={rating}
                                                                            className='rating_star'

                                                                        />

                                                                    </Box>

                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_5" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_5">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseFinancialAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_5">
                                                                <span>{t('financial')}</span>
                                                                <i className={financialAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {financialAccordingList ?
                                                        <div id="collapseOne_5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_5">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-12">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            displayEmpty
                                                                                            title={t('selectPurchaseType')}
                                                                                            value={financial}
                                                                                            onChange={(e) => { setFinancial(e.target.value); }}
                                                                                            input={<OutlinedInput />}
                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('selectPurchaseType')}</em>
                                                                                            </MenuItem>
                                                                                            {leadsDropdownData?.FinancialStatuses?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>


                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_7">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseLegalAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                                <span>{t('legalRepresentative')}</span>
                                                                <i className={legalAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {legalAccordingList ?
                                                        <>
                                                            <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                                <div class="panel-body">
                                                                    <div class="card">
                                                                        <div className='form_block'>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" title={t('firstName')} placeholder={t('firstName')} value={legalFirstName} onChange={(e) => { setlegalFirstName(e.target.value); }} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" title={t('lastName')} placeholder={t('lastName')} value={legalLastName} onChange={(e) => { setlegalLastName(e.target.value); }} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" title={t('email')} placeholder={t('email')} value={legalEmail} onChange={(e) => { setlegalEmail(e.target.value) }} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">

                                                                                    <div class="form-group phone-input">
                                                                                        <Tooltip title={'Contact No.'} arrow style={{ backgroundColor: '#fff' }} className="tooltip_notes">
                                                                                            <div>
                                                                                                <PhoneInput
                                                                                                    className="contact_form_control"
                                                                                                    placeholder='Contact No.'
                                                                                                    country={"bh"}
                                                                                                    enableSearch={true}
                                                                                                    value={legalContact}
                                                                                                    onChange={(e) => { setlegalContact(e) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="button_block">
                                                                                <a href="#" class="links" onClick={() => opportunityApi()}>{t('save')}</a>
                                                                                <a href="#" class="links" onClick={() => opportunityNextApi()}>{t('saveAndNext')}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="rightside_block">
                                                <h4>{t('management')}</h4>
                                                {
                                                    userRole == 'AgentAdmin' && (
                                                        <div>
                                                            <FormControl className='form-group' sx={{}}>
                                                                <Select
                                                                    className='form-control border_temp'
                                                                    sx={{ borderColor: 'transparent' }}
                                                                    displayEmpty
                                                                    value={reference}
                                                                    onChange={(e) => { { setReference(e.target.value); }; }}
                                                                    input={<OutlinedInput />}
                                                                    title='Assign to'
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                width: 250,
                                                                            },
                                                                        },
                                                                        disableScrollLock: true,
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem className='drop_menus' value="">
                                                                        <em className='select_text'>Assign to</em>
                                                                    </MenuItem>
                                                                    {leadsDropdownData?.AssignedTo?.map((item) => (
                                                                        <MenuItem
                                                                            className='drop_menus'
                                                                            key={item?.Id}
                                                                            value={item?.Id}
                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                        >
                                                                            {item?.Name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>
                                                        </div>
                                                    )
                                                }

                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        <Select
                                                            className='form-control border_temp'
                                                            sx={{ borderColor: 'transparent' }}
                                                            title={t('status')}
                                                            displayEmpty
                                                            value={leadStatus}
                                                            onChange={(e) => { setLeadStatus(e.target.value); }}
                                                            input={<OutlinedInput />}

                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                        width: 250,
                                                                    },
                                                                },
                                                                disableScrollLock: true,
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem className='drop_menus' value="">
                                                                <em className='select_text'>{t('status')}</em>
                                                            </MenuItem>
                                                            {leadsDropdownData?.LeadStatuses?.map((item) => (
                                                                <MenuItem
                                                                    className='drop_menus'
                                                                    key={item?.Id}
                                                                    value={item?.Id}
                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                >
                                                                    {item?.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </div>
                                                <div class="update_block">
                                                    <p><span></span></p>

                                                    <p><span>{t('lastUpdate')} : </span>{lastUpdateValue}</p>
                                                    <p><span>Created : </span>{createdValue}</p>
                                                    <p><span>{t('createdBy')} : </span>{createdByFirstName} {createdByLastName}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            {
                                value == 'two' ?
                                    <>
                                        {
                                            leadByIdData?.Lead?.MinBudget == null && (
                                                <div class="main_class">
                                                    <div class="requirement_set_block">
                                                        <img src={lead} />
                                                        <h5 class="requirement_set_title">{t('noRequirement')}</h5>
                                                        <span class="requirement_set_description">{t('providingAccurateLead')}</span>
                                                        <a onClick={handleOpen} class="links">Add a Requirements</a>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_7">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openCloseRequirementAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                            <span>{t('leadRequirements')}</span>
                                                            <i className={requirementAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {requirementAccordingList ?
                                                    <>
                                                        <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="requirement_block">
                                                                        <p>{leadByIdData?.Lead?.PropertyType?.Name} for {leadByIdData?.Lead?.Category?.Name} ({leadByIdData?.Lead?.OfferingTypes?.Name})</p>
                                                                        <p><img src={dollar} />Up to {leadByIdData?.Lead?.MaxBudget} BHD</p>
                                                                        <p><img src={bed} />{leadByIdData?.Lead?.MinBedrooms == -1 ? "Studio" : leadByIdData?.Lead?.MinBedrooms} to {leadByIdData?.Lead?.MaxBedrooms == -1 ? "Studio" : leadByIdData?.Lead?.MaxBedrooms} from {leadByIdData?.Lead?.MinSize} to {leadByIdData?.Lead?.MaxSize} sqm</p>
                                                                        <p><img src={locationIcon} />{leadByIdData?.Lead?.PRAddress}</p>
                                                                        {
                                                                            leadByIdData?.Lead?.MinBudget != null && (
                                                                                <a href='#' onClick={() => setOpen(true)}>{t('editLeadRequirements')}</a>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                        <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_7">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openCloseShortlistAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                            <span>{t('shortListedProperties')}</span>
                                                            <i className={shortlistAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {shortlistAccordingList ?
                                                    <>
                                                        <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div className='shortlist_dropdown'>
                                                                        {
                                                                            sortListData?.length != 0 && (
                                                                                <p class="props_title">Inventory ({sortListData?.length})</p>
                                                                            )
                                                                        }
                                                                        {
                                                                            sortListData?.length != 0 && (
                                                                                <div>

                                                                                    <Menu
                                                                                        id="long-menu"
                                                                                        className='drop_menus'
                                                                                        MenuListProps={{
                                                                                            'aria-labelledby': 'long-button',
                                                                                        }}
                                                                                        anchorEl={shortlistDropdownAnchorEl}
                                                                                        open={shortlistDropdownOpen}
                                                                                        onClose={shortlistDropdownHandleClose}
                                                                                        PaperProps={{
                                                                                            style: {
                                                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                width: '20ch',
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {options.map((option) => (
                                                                                            <MenuItem className='drop_menus' key={option} selected={option === 'Pyxis'} onClick={() => shortlistDropdownHandleClose(option)}>
                                                                                                {option}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Menu>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    {
                                                                        sortListData?.length != 0 ?
                                                                            <div>
                                                                                {
                                                                                    sortListData?.map((item) => {

                                                                                        return (
                                                                                            <div>
                                                                                                <div class="props_detail">
                                                                                                    <div class="row clearfix d-flex">
                                                                                                        <div class="col-md-4">
                                                                                                            {
                                                                                                                item?.ImageList.length != 0 ?

                                                                                                                    <div style={{ height: 240 }} class="property_list_image">
                                                                                                                        <Carousel images={item?.ImageList} />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div class="property_list_image">
                                                                                                                        <img src={require("../../images/no_image.jpg")} />
                                                                                                                    </div>

                                                                                                            }
                                                                                                        </div>
                                                                                                        <div class="col-md-8 align-self-center">
                                                                                                            <div class="property_list_detail">
                                                                                                                <h2>{item?.Title}</h2>
                                                                                                                <div class="amenities_details">
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <span>BHD {item?.MonthlyPrice}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={bed}></img>
                                                                                                                        <span>{item?.Noofbedrooms == -1 ? "Studio" : item?.Noofbedrooms}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={bathtub}></img>
                                                                                                                        <span>{item?.Noofbathrooms}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={area}></img>
                                                                                                                        <span>{item?.Size} sqm</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <p class="addressing"><img src={locationIcon} />{item?.Location}.</p>
                                                                                                                <div class="amenities_details">
                                                                                                                    <p class="status green">{item?.Availability}</p>
                                                                                                                    <div class="amenities_detail_block last">
                                                                                                                        <span>Assigned to {item?.AssignedUserName}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="like_property">
                                                                                                        <div>
                                                                                                            <a onClick={() => onClickAddShortlistedPropertiesApi(item?.Id)}><img class="like_icon" src={likeFillIcon} /></a>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div> :
                                                                            <h1 class="text-center">No Data Found</h1>
                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                        <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_7">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openCloseMatchingAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                            <span>{t('matchingListings')}</span>
                                                            <i className={matchingAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {matchingAccordingList ?
                                                    <>
                                                        <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div className='shortlist_dropdown'>
                                                                        {
                                                                            matchingListingsData?.Data?.length != 0 && (
                                                                                <p class="props_title">Inventory ({matchingListingsData?.Data?.length})</p>
                                                                            )
                                                                        }

                                                                        {
                                                                            matchingListingsData?.Data?.length != 0 && (
                                                                                <div>

                                                                                    <Menu
                                                                                        id="long-menu"
                                                                                        className='drop_menus'
                                                                                        MenuListProps={{
                                                                                            'aria-labelledby': 'long-button',
                                                                                        }}
                                                                                        anchorEl={matchingDropdownAnchorEl}
                                                                                        open={matchingDropdownOpen}
                                                                                        onClose={matchingDropdownHandleClose}
                                                                                        PaperProps={{
                                                                                            style: {
                                                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                width: '20ch',
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {matchOptions.map((matchOptions) => (
                                                                                            <MenuItem className='drop_menus' key={matchOptions} selected={matchOptions === 'Pyxis'} onClick={() => matchingDropdownHandleClose(matchOptions)}>
                                                                                                {matchOptions}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Menu>
                                                                                </div>)
                                                                        }
                                                                    </div>
                                                                    {
                                                                        matchingListingsData?.Data?.length != 0 ?
                                                                            <div>
                                                                                {
                                                                                    matchingListingsData?.Data?.map((item) => {

                                                                                        return (
                                                                                            <div>
                                                                                                <div class="props_detail">
                                                                                                    <div class="row clearfix d-flex">
                                                                                                        <div class="col-md-4">
                                                                                                            {
                                                                                                                item?.ImageList.length != 0 ?
                                                                                                                    // <div class="property_list_image">
                                                                                                                    //     <img src={item?.Url} />
                                                                                                                    // </div>
                                                                                                                    <div style={{ height: 240 }} class="property_list_image">
                                                                                                                        <Carousel images={item?.ImageList} />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div class="property_list_image">
                                                                                                                        <img src={require("../../images/no_image.jpg")} />
                                                                                                                    </div>

                                                                                                            }
                                                                                                        </div>
                                                                                                        <div class="col-md-8 align-self-center">
                                                                                                            <div class="property_list_detail">
                                                                                                                <h2>{item?.Title}</h2>
                                                                                                                <div class="amenities_details">
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <span>BHD {item?.MonthlyPrice}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={bed}></img>
                                                                                                                        <span>{item?.Noofbedrooms == -1 ? "Studio" : item?.Noofbedrooms}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={bathtub}></img>
                                                                                                                        <span>{item?.Noofbathrooms}</span>
                                                                                                                    </div>
                                                                                                                    <div class="amenities_detail_block">
                                                                                                                        <img src={area}></img>
                                                                                                                        <span>{item?.Size} sqm</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <p class="addressing"><img src={locationIcon} />{item?.Location}.</p>
                                                                                                                <div class="amenities_details">
                                                                                                                    <p class="status green">{item?.Availability}</p>
                                                                                                                    <div class="amenities_detail_block last">
                                                                                                                        <span>Assigned to {item?.AssignedUserName}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="like_property">
                                                                                                        <div>
                                                                                                            <a onClick={() => onClickAddShortlistedPropertiesApi(item?.Id)}><img class="like_icon" src={item?.IsShortListed == true ? likeFillIcon : likeIcon} /></a>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div> :
                                                                            <h1 class="text-center">No Data Found</h1>
                                                                    }


                                                                    <div class="button_block">
                                                                        <a href="#" class="links" onClick={() => onClickPropertyBack()}>{t('back')}</a>
                                                                        <a href="#" class="links" onClick={() => onClickPropertySave()}>{t('save')}</a>
                                                                        <a href="#" class="links" onClick={() => onClickPropertyNext()}>{t('saveAndNext')}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'three' ?
                                    <>
                                        <div class="main_class">
                                            <div class="form_block">
                                                <div class="form-group notes">
                                                    <div class="form-line">
                                                        <textarea rows="4" class="form-control no-resize" placeholder={t('WriteNewNote')} value={notes} onChange={(e) => setnotes(e.target.value)} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => addLeadNotes()} >{t('Save')}</a>
                                            </div>
                                            {
                                                notesError && (
                                                    <span class="validation_notes">{notesError}</span>

                                                )
                                            }
                                        </div>
                                        {
                                            notesListLeadData?.Data?.map((item) => {
                                                return (
                                                    <div class="main_class">
                                                        <div class="note_block">
                                                            <div class="note_person">
                                                                <div class="note_person_image">
                                                                    <img src={require("../../images/note_person_image.png")} />
                                                                    <div class="person_detail">
                                                                        <h5>{item?.UserName}</h5>
                                                                        <p>{t('lastUpdate')}: {item?.DateDifference}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="note_time">

                                                                </div>
                                                            </div>
                                                            <p>{item?.Notes}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div class="button_block">
                                            <a href="#" class="links" onClick={() => onClickNotesBack()}>{t('back')}</a>
                                            <a href="#" class="links" onClick={() => onClickNotesNext()}>{t('Next')}</a>
                                        </div>
                                    </> : null
                            }

                            {
                                value == 'four' ?
                                    <>
                                        <div class="main_class">
                                            <div class="file-drop-area">
                                                <img src={folder} />
                                                <span class="file-message">{t('dragAndDrop')}</span>
                                                <span class="choose-file-button">{t('uploadADocument')}</span>
                                                <input class="file-input" type="file" multiple value={propertyDocument} onChange={handleFileChange} />
                                                {
                                                    propertyDocumentError && (
                                                        <span class="validation_notes">{propertyDocumentError}</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        documentsListLeadData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>
                                                                        <th>{t('contact')}</th>
                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    documentsListLeadData?.Data?.map((item) => {
                                                                        return (
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.RowNum}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Name}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Phone}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <a onClick={() => handleDownload(item)}>
                                                                                            <p class='blue_text'>{item?.FileName}</p>
                                                                                        </a>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail">
                                                                                            <a href="#" class="link red" onClick={() => handleOpenPoppup(item?.Id)}><img src={deleteIcon} /></a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        )
                                                                    })
                                                                }

                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={documentsListLeadData?.TotalPages} page={page} onChange={pageHandleChange} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => onClickDocumentBack()}>{t('back')}</a>
                                                <a href="#" class="links" onClick={(e) => onClickDocumentNext(e)}>{t('done')}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block add_requirements">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Add a Requirements</h2>
                            <button onClick={handleClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block requirements_body_block">
                            <div class="type_categories_block">
                                <h5>Location</h5>
                                <div class="full_width_dropdown">
                                    <div>
                                        <FormControl className='form-group' sx={{}}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                sx={{ width: 300, fontSize: 14, }}
                                                className='form-control auto_complete'
                                                onChange={handleSelect}
                                                title='City'
                                                options={options}
                                                getOptionLabel={(option) => option?.Name} // Adjust this based on your API response structure
                                                onInputChange={(event, newInputValue) => handleLocationSearch(newInputValue)}
                                                renderInput={(params) => <TextField {...params} placeholder={prAddressSelected == null ? "City" : prAddressSelected == "" ? "City" : prAddressSelected}

                                                />}
                                            />
                                        </FormControl>
                                    </div>

                                </div>
                                <h5>Offering Type</h5>
                                <div class="row clearfix no-gutter">
                                    <div class="col-sm-6">
                                        <div class="type_block border_right">
                                            <div class="row clearfix">
                                                <div class="col-sm-6" >
                                                    <a class={residentCategory == 2 ? "types active" : "types deactive"} onClick={() => { setResidentCategory(2); setPropertyTypeSelected("") }} >
                                                        <img src={residential} />
                                                        <h6>{t('residential')}</h6>
                                                    </a>
                                                </div>
                                                <div class="col-sm-6">
                                                    <a class={residentCategory == 1 ? "types active" : "types deactive"} onClick={() => { setResidentCategory(1); setPropertyTypeSelected("") }}>
                                                        <img src={commercial} />
                                                        <h6>{t('commercial')}</h6>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="type_block">
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <a class={rentalCategory == 1 ? "types active" : "types deactive"} onClick={() => { setRentalCategory(1); setPropertyTypeSelected("") }} >
                                                        <img src={rent} />
                                                        <h6>{t('rent')}</h6>
                                                    </a>
                                                </div>
                                                <div class="col-sm-6">
                                                    <a class={rentalCategory == 2 ? "types active" : "types deactive"} onClick={() => { setRentalCategory(2); setPropertyTypeSelected("") }} >
                                                        <img src={sale} />
                                                        <h6>{t('sale')}</h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="type_block property_type">
                                    <h5>Property Types</h5>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            sx={{ borderColor: 'transparent' }}
                                            title={t('propertyType')}
                                            displayEmpty
                                            value={propertyTypeSelected}
                                            onChange={(e) => { setPropertyTypeSelected(e.target.value) }}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('propertyType')}</em>
                                            </MenuItem>
                                            {selectedPropertyTypeDropdown?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </div>
                                <h5>Budget</h5>
                                <div class="assessment_range_block">

                                    <Slider
                                        range
                                        value={budgetValue}
                                        onChange={budgetHandleChange}
                                        min={0}
                                        max={5000000}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}

                                    />
                                    <span class='left'>BHD {budgetValue[0]}</span>
                                    <span class='right'>BHD {budgetValue[1]}</span>
                                </div>
                                <h5>Size</h5>
                                <div class="assessment_range_block">
                                    <Slider
                                        range
                                        value={sizeValue}
                                        onChange={sizeHandleChange}
                                        min={0}
                                        max={9000}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}

                                    />
                                    <span class='left'>Sqm {sizeValue[0]}</span>
                                    <span class='right'>Sqm {sizeValue[1]}</span>
                                </div>
                                <h5>Bedrooms</h5>
                                <div class="assessment_range_block">

                                    <Slider
                                        range
                                        value={bedroomValue}
                                        onChange={bedroomHandleChange}
                                        min={-1}
                                        max={20}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}

                                    />
                                    <span class='left'>{bedroomValue[0] === -1 ? "Studio" : bedroomValue[0]}</span>
                                    <span class='right'>{bedroomValue[1] === -1 ? "Studio" : bedroomValue[1]}</span>
                                </div>
                                <h5>Select Amenities</h5>
                                <div class="amenities_list requirements">
                                    <div>
                                        {leadsDropdownData?.Amenities.map((chip) => (
                                            <Chip
                                                key={chip?.Id}
                                                label={chip?.Name}
                                                color={selectedChips?.includes(chip?.Id) ? 'primary' : 'default'}
                                                onClick={() => handleChipClick(chip?.Id)}
                                                style={{ margin: '5px', fontSize: '14px', padding: '6px 15px', borderRadius: '3px' }}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div class="form_block requirement">
                                    <div class="row clearfix">
                                        <div class="col-sm-6">
                                            <div>
                                                <FormControl className='form-group' sx={{}}>
                                                    <Select
                                                        className='form-control border_temp'
                                                        sx={{ borderColor: 'transparent' }}
                                                        title={t('timeFrame')}
                                                        displayEmpty
                                                        value={timeFrameName}
                                                        onChange={(e) => { setTimeFrameName(e.target.value); }}
                                                        input={<OutlinedInput />}

                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                    width: 250,
                                                                },
                                                            },
                                                            disableScrollLock: true,
                                                        }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem className='drop_menus' value="">
                                                            <em className='select_text'>{t('timeFrame')}</em>
                                                        </MenuItem>
                                                        {leadsDropdownData?.TimeFrameIds?.map((item) => (
                                                            <MenuItem
                                                                className='drop_menus'
                                                                key={item?.Id}
                                                                value={item?.Id}
                                                                style={getStyles(item?.Name, personName, theme)}
                                                            >
                                                                {item?.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" onClick={() => onClickAddLeadRequirement()}>Save</a>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={addPropertyOpen}
                    onClose={addPropertyHandleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block add_requirements">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Add Property</h2>
                            <button onClick={addPropertyHandleClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <p>Fill in a property reference ID of one of your inventory asset</p>
                            <div>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" placeholder={t('unitNo')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links">Cancel</a>
                                <a href="#" class="links">add</a>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
            <ToastContainer />
        </div>
    );

}


