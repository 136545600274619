import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import plus from '../../images/plus.svg';
import pdf_icon from '../../images/pdf_icon.png'
import bed from '../../images/bed.svg';
import area_icon from '../../images/area_icon.svg';
import bathtub from '../../images/bathtub.svg';
import info from '../../images/info.svg';
import edit from '../../images/edit.svg';
import deleteIcon from '../../images/delete.svg';
import dateRange from '../../images/date_range.svg';
import Slide from '@mui/material/Slide';
import sofa from '../../images/sofa.svg';
import dollar from '../../images/dollar.svg';
import bicycle from '../../images/bicycle.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import refresh from '../../images/refresh.svg';
import calculator from '../../images/calculator.svg';
import closeModal from '../../images/close.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Modal, TextField, Button, Backdrop, CircularProgress, Typography, Tooltip, Autocomplete, Checkbox } from '@mui/material';
import { useTranslation } from "react-i18next";
import Pagination from '@mui/material/Pagination';
import Carousel from '../../component/Carousel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';




// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyFilterListApi, PropertyMasterDropdownApi, calculateFlexCostApi, checkPublishCreditsApi, deletePropertyApi, getPropertyDetailApi, locationListApi, logoutApi, ownerPropertyModeApi, propertyCategoryApi, propertyDetailDataApi, propertyModeApi, propertyTypeApi } from '../../redux/ApiActionCreator';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import axios from 'axios';
import config from '../../redux/config';
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const numbers = [
    {
        Id: 1,
        Name: "1"
    },
    {
        Id: 2,
        Name: "2"
    },
    {
        Id: 3,
        Name: "3"
    },
    {
        Id: 4,
        Name: "4"
    },
    {
        Id: 5,
        Name: "5"
    },
    {
        Id: 6,
        Name: "6"
    },
    {
        Id: 7,
        Name: "7"
    },
    {
        Id: 8,
        Name: "8"
    },
    {
        Id: 9,
        Name: "9"
    },
    {
        Id: 10,
        Name: "10"
    },
    {
        Id: 11,
        Name: "11"
    },
    {
        Id: 12,
        Name: "12"
    },
    {
        Id: 13,
        Name: "13"
    },
    {
        Id: 14,
        Name: "14"
    },
    {
        Id: 15,
        Name: "15"
    },
    {
        Id: 16,
        Name: "16"
    },
    {
        Id: 17,
        Name: "17"
    },
    {
        Id: 18,
        Name: "18"
    },
    {
        Id: 19,
        Name: "19"
    },
    {
        Id: 20,
        Name: "20"
    },
];




function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function PropertyList() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const theme = useTheme();
    const navigate = useNavigate()
    const [personName, setPersonName] = useState([])
    const [referenceNo, setreferenceNo] = useState('')
    const [loading, setLoading] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [locationSelected, setLocationSelected] = useState(null)
    const [offeringTypeSelected, setOfferingTypeSelected] = useState('')
    const [boostingProductsSelected, setBoostingProductsSelected] = useState('')
    const [furnishingTypeSelected, setFurnishingTypeSelected] = useState('')
    const [stateSelected, setStateSelected] = useState('')
    const [userSelected, setUserSelected] = useState('')
    const [propertyPopupOpen, setpropertyPopupOpen] = useState(false)
    const [propertyCategorySelected, setPropertyCategorySelected] = useState('')
    const [propertyStatusSelected, setPropertyStatusSelected] = useState('')
    const [statusSelected, setStatusSelected] = useState('')
    const [propertyTypeSelected, setPropertyTypeSelected] = useState('')
    const [ownerSelected, setOwnerSelected] = useState('')
    const [unitNoSelected, setUnitNoSelected] = useState('')
    const [selectAminitiesSelected, setSelectAminitiesSelected] = useState([])
    const [mainSubscriptionTypeSelected, setMainSubscriptionTypeSelected] = useState("")
    const [subscriptionIdSelectedFeatured, setSubscriptionIdSelectedFeatured] = useState(2)
    const [subscriptionIdSelectedPremium, setSubscriptionIdSelectedPremium] = useState(4)
    const [propertyCurrentSubscriptionPlanId, setPropertyCurrentSubscriptionPlanId] = useState("")
    const [getCreditBalance, setGetCreditBalance] = useState("")
    const [propertyIdToPublish, setPropertyIdToPublish] = useState("")
    const [unPublishModal, setUnPublishModal] = useState(false)
    const [unPublishPropertyId, setUnPublishPropertyId] = useState('')
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const mimicFlag = useSelector((state) => state?.apiReducer?.mimicFlag)

    const priceTypeOptionsFlexCostRent = [
        {
            Id: 1,
            Name: "BHD/Month"
        }
    ];

    const priceTypeOptionsFlexCostSale = [
        {
            Id: 1,
            Name: "BHD"
        }
    ];
    const [priceTypeSelectedFlexCostRent, setPriceTypeSelectedFlexCostRent] = useState(1)
    const [priceTypeSelectedFlexCostSale, setPriceTypeSelectedFlexCostSale] = useState(1)
    const [bedroomsFlexCalculator, setBedroomsFlexCalculator] = useState('')
    const [bathroomsroomsFlexCalculator, setBathroomsFlexCalculator] = useState('')
    const [categorySelectedFlexCalculator, setCategorySelectedFlexCalculator] = useState(1)
    const [locationSelectedFlexCalculator, setLocationSelectedFlexCalculator] = useState('')
    const [propertyTypeSelectedFlexCalculator, setPropertyTypeFlexCalculator] = useState('')
    const [priceFlexCostCalculator, setPriceFlexCostCalculator] = useState("")
    const [locationSelectedFlexCalculatorError, setLocationSelectedFlexCalculatorError] = useState('')
    const [propertyTypeSelectedFlexCalculatorError, setPropertyTypeFlexCalculatorError] = useState('')
    const [priceFlexCostCalculatorError, setPriceFlexCostCalculatorError] = useState("")
    const [featuredAutoRenewCheckBox, setFeaturedAutoRenewCheckBox] = useState(true)
    const [premiumAutoRenewCheckBox, setPremiumAutoRenewCheckBox] = useState(true)


    const priceOptionsDisplay = categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? priceTypeOptionsFlexCostRent : priceTypeOptionsFlexCostSale
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)

    const propertyFilterData = useSelector((state) => state?.apiReducer?.propertyFilterData)
    const propertyMasterData = useSelector((state) => state?.apiReducer?.propertyMasterData)
    const propertyFilterLoad = useSelector((state) => state?.apiReducer?.propertyFilterLoad)
    const flexCostData = useSelector((state) => state?.apiReducer?.calculateFlexCostData)
    const checkPublishCreditsData = useSelector((state) => state?.apiReducer?.checkPublishCreditsData)
    const [open, setOpen] = useState(false);

    const [page, setPage] = React.useState(1);
    const [bathroomValue, bathroomSetValue] = useState([0, 20]);
    const [priceValue, priceSetValue] = useState([0, 9999999]);
    const [areaValue, areaSetValue] = useState([0, 999999]);
    const [bedroomValue, bedroomSetValue] = useState([-1, 20]);
    const [selectPropertDelete, setselectPropertDelete] = useState('')
    const [collapsedRows, setCollapsedRows] = useState({});
    const [options, setOptions] = useState([]);
    const [stateIdSelected, setStateIdSelected] = useState('')
    const [cityIdSelected, setCityIdSelected] = useState('')
    const [upgradePlanOpen, setUpgradePlanOpen] = React.useState(false);
    const [publishOpen, setPublishOpen] = React.useState(false);



    const selectedPropertyTypeDropdown = propertyCategorySelected == 2 && offeringTypeSelected == 1 ? propertyMasterData?.ResidentialRent : propertyCategorySelected == 2 && offeringTypeSelected == 2 ? propertyMasterData?.ResidentialSale : propertyCategorySelected == 1 && offeringTypeSelected == 1 ? propertyMasterData?.CommertialRent : propertyMasterData?.CommertialSale

    const selectedPropertyTypeDropdownFlexCalculator = categorySelectedFlexCalculator == 1 ? propertyMasterData?.ResidentialRent : categorySelectedFlexCalculator == 2 ? propertyMasterData?.ResidentialSale : categorySelectedFlexCalculator == 3 ? propertyMasterData?.CommertialRent : propertyMasterData?.CommertialSale


    const handleFeaturedAutoRenewCheckBox = (event) => {

        setFeaturedAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const handlePremiumAutoRenewCheckBox = (event) => {


        setPremiumAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const bathroomHandleChange = (newValue) => {
        bathroomSetValue(newValue);
    };

    const priceHandleChange = (newValue) => {
        priceSetValue(newValue);
    };

    const areaHandleChange = (newValue) => {
        areaSetValue(newValue);
    };

    const bedroomHandleChange = (newValue) => {
        bedroomSetValue(newValue);
    };

    const handleStyle = {
        backgroundColor: '#fff', // Change the thumb color
        borderColor: '#FDB51F', // Change the border color
    };

    const trackStyle = {
        backgroundColor: '#FDB51F', // Change the track color
    };


    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };
    const getCreditBalanceApiCall = async (params) => {
        setIsRefreshing(true)
        try {
            const response = await axios.get(`${config.baseURL}/Credit/GetCreditBalance`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    setGetCreditBalance(response?.data?.TotalCredits)
                    setTimeout(() => {
                        setIsRefreshing(false)

                    }, 1000);
                } else if (response.data.Status == 'ERROR') {
                    setTimeout(() => {
                        setIsRefreshing(false)

                    }, 1000);

                }

            } else {
                setTimeout(() => {
                    setIsRefreshing(false)

                }, 1000);
            }
        } catch (error) {
            setTimeout(() => {
                setIsRefreshing(false)

            }, 1000);

        }
    };

    //GET CREDIT API CALL
    useEffect(() => {
        getCreditBalanceApiCall()
    }, [])



    // MASTER DROPDOWN API CALL
    useEffect(() => {
        dispatch(PropertyMasterDropdownApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });
    }, [])


    // PROPERTY FILTER API CALL
    useEffect(() => {
        const params = {
            ReferenceNo: referenceNo == "" ? null : referenceNo,
            OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
            BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
            FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
            PropertyStateId: stateSelected == "" ? null : stateSelected,
            UserId: userSelected == "" ? null : userSelected,
            MinBedrooms: bedroomValue[0],
            MaxBedrooms: bedroomValue[1],
            PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
            MinPrice: priceValue[0],
            MaxPrice: priceValue[1],
            PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
            Status: statusSelected == "" ? null : statusSelected,
            MinBathroom: bathroomValue[0],
            MaxBathroom: bathroomValue[1],
            PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
            Owner: ownerSelected == "" ? null : ownerSelected,
            UnitNo: unitNoSelected == "" ? null : unitNoSelected,
            Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
            MinSize: areaValue[0],
            MaxSize: areaValue[1],
            CityId: cityIdSelected == "" ? null : cityIdSelected,
            StateId: stateIdSelected == "" ? null : stateIdSelected,
            pageNumber: page

        }
        dispatch(PropertyFilterListApi(token, params))

    }, [page,])



    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectAminitiesSelected(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    // ONCLICK DELETE PROPERTY
    const onClickDeleteProperty = () => {
        setLoading(true)
        dispatch(deletePropertyApi(token, selectPropertDelete))
            .then((response) => {
                const params = {
                    pageNumber: 1,
                    ReferenceNo: referenceNo == "" ? null : referenceNo,
                    OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                    BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                    FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                    PropertyStateId: stateSelected == "" ? null : stateSelected,
                    UserId: userSelected == "" ? null : userSelected,
                    MinBedrooms: bedroomValue[0],
                    MaxBedrooms: bedroomValue[1],
                    PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                    MinPrice: priceValue[0],
                    MaxPrice: priceValue[1],
                    PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                    Status: statusSelected == "" ? null : statusSelected,
                    MinBathroom: bathroomValue[0],
                    MaxBathroom: bathroomValue[1],
                    PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                    Owner: ownerSelected == "" ? null : ownerSelected,
                    UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                    Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                    MinSize: areaValue[0],
                    MaxSize: areaValue[1],
                    CityId: cityIdSelected == "" ? null : cityIdSelected,
                    StateId: stateIdSelected == "" ? null : stateIdSelected

                }
                dispatch(PropertyFilterListApi(token, params))
                    .then((response) => {
                        setPage(1)
                        setLoading(false)
                        setpropertyPopupOpen(false);
                    })
                    .catch((error) => {
                        setPage(1)
                        setLoading(false)


                    });

            })
            .catch((error) => {


            });
    }

    const unpublishApiCall = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Property/Unpublish?PropertyId=${unPublishPropertyId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        pageNumber: page,
                        ReferenceNo: referenceNo == "" ? null : referenceNo,
                        OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                        BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                        FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                        PropertyStateId: stateSelected == "" ? null : stateSelected,
                        UserId: userSelected == "" ? null : userSelected,
                        MinBedrooms: bedroomValue[0],
                        MaxBedrooms: bedroomValue[1],
                        PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                        MinPrice: priceValue[0],
                        MaxPrice: priceValue[1],
                        PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                        Status: statusSelected == "" ? null : statusSelected,
                        MinBathroom: bathroomValue[0],
                        MaxBathroom: bathroomValue[1],
                        PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                        Owner: ownerSelected == "" ? null : ownerSelected,
                        UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                        Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                        MinSize: areaValue[0],
                        MaxSize: areaValue[1],
                        CityId: cityIdSelected == "" ? null : cityIdSelected,
                        StateId: stateIdSelected == "" ? null : stateIdSelected

                    }
                    dispatch(PropertyFilterListApi(token, params))
                        .then((response) => {
                            setPage(page)
                            setLoading(false)
                            setUnPublishModal(false);
                        })
                        .catch((error) => {
                            setPage(page)
                            setLoading(false)


                        });



                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        } catch (error) {


        }
    };

    // ONCLICK UNPUBLISH PROPERTY
    const onClickUnpunlishProperty = () => {

        unpublishApiCall()

    }
    // DATE FORMATER
    function formatDate(input) {
        const date = new Date(input);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // ONCLICK PROPERTY CREATE
    const onClickPropertyCreate = () => {
        dispatch(propertyModeApi(0))
        dispatch(ownerPropertyModeApi(0))
        dispatch(propertyDetailDataApi('create', ''))
        navigate('/PropertyDetail')
    }

    // ONCLICK PROPERTY EDIT
    const onClickPropertyEdit = (id) => {
     
        setLoading(true)
        dispatch(getPropertyDetailApi(token, id))
            .then((response) => {
                dispatch(propertyModeApi(1))
                dispatch(ownerPropertyModeApi(0))
                dispatch(propertyDetailDataApi('edit', ''))
           
                setLoading(false)
                navigate('/PropertyDetail')
            })
            .catch((error) => {
                toast.error("This Property does not Exist", {
                    position: 'top-right',
                    autoClose: 1000, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                const params = {
                    pageNumber: 1,
                    ReferenceNo: referenceNo == "" ? null : referenceNo,
                    OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                    BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                    FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                    PropertyStateId: stateSelected == "" ? null : stateSelected,
                    UserId: userSelected == "" ? null : userSelected,
                    MinBedrooms: bedroomValue[0],
                    MaxBedrooms: bedroomValue[1],
                    PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                    MinPrice: priceValue[0],
                    MaxPrice: priceValue[1],
                    PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                    Status: statusSelected == "" ? null : statusSelected,
                    MinBathroom: bathroomValue[0],
                    MaxBathroom: bathroomValue[1],
                    PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                    Owner: ownerSelected == "" ? null : ownerSelected,
                    UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                    Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                    MinSize: areaValue[0],
                    MaxSize: areaValue[1],
                    CityId: cityIdSelected == "" ? null : cityIdSelected,
                    StateId: stateIdSelected == "" ? null : stateIdSelected

                }
                dispatch(PropertyFilterListApi(token, params))
                    .then((response) => {
                        setPage(1)
                        setLoading(false)
                    })
                    .catch((error) => {
                        setPage(1)
                        setLoading(false)


                    });



            });

    }


    const toggleRowCollapse = (itemId) => {
        setCollapsedRows((prevCollapsedRows) => ({
            ...prevCollapsedRows,
            [itemId]: !prevCollapsedRows[itemId],
        }));
    };

    const handleClickOpen = (id) => {

        setselectPropertDelete(id)
        setpropertyPopupOpen(true);
    };

    const handleClosepopup = () => {

        setpropertyPopupOpen(false);
    };
    const handleOpenUnpublishpopup = (id) => {

        setUnPublishPropertyId(id)
        setUnPublishModal(true)
    };

    const handleCloseUnpublishpopup = () => {
        setUnPublishModal(false)
    }

    // ONCLICK RESET PROPERTY API CALL
    const resetPropertyFilter = () => {
        setLoading(true)
        setreferenceNo('')
        setLocationSelected(null)
        setOfferingTypeSelected('')
        setBoostingProductsSelected('')
        setFurnishingTypeSelected('')
        setStateSelected('')
        setUserSelected('')
        bedroomSetValue([-1, 20])
        setPropertyCategorySelected('')
        priceSetValue([0, 9999999])
        setPropertyStatusSelected('')
        setStatusSelected('')
        bathroomSetValue([0, 20])
        setPropertyTypeSelected('')
        setOwnerSelected('')
        setUnitNoSelected('')
        setSelectAminitiesSelected([])
        areaSetValue([0, 999999])
        setOptions([])
        setStateIdSelected('')
        setCityIdSelected('')

        const params = {

            pageNumber: 1,


        }
        dispatch(PropertyFilterListApi(token, params))
            .then(() => {
                setCollapsedRows({})
                setLoading(false)
                setPage(1)
            })
            .catch(error => {
                setCollapsedRows({})
                setLoading(false)
                setPage(1)

            })

    }


    // ONCLICK PROPERTY FILTER
    const handleSearch = () => {
        setPage(1)
        setLoading(true)
        const params = {
            ReferenceNo: referenceNo == "" ? null : referenceNo,
            OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
            BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
            FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
            PropertyStateId: stateSelected == "" ? null : stateSelected,
            UserId: userSelected == "" ? null : userSelected,
            MinBedrooms: bedroomValue[0],
            MaxBedrooms: bedroomValue[1],
            PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
            MinPrice: priceValue[0],
            MaxPrice: priceValue[1],
            PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
            Status: statusSelected == "" ? null : statusSelected,
            MinBathroom: bathroomValue[0],
            MaxBathroom: bathroomValue[1],
            PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
            Owner: ownerSelected == "" ? null : ownerSelected,
            UnitNo: unitNoSelected == "" ? null : unitNoSelected,
            Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
            MinSize: areaValue[0],
            MaxSize: areaValue[1],
            CityId: cityIdSelected == "" ? null : cityIdSelected,
            StateId: stateIdSelected == "" ? null : stateIdSelected,
            pageNumber: 1




        }

        dispatch(PropertyFilterListApi(token, params))
            .then(() => {
                setCollapsedRows({})
                setLoading(false)
                // setPage(1)

            })
            .catch(error => {
                setCollapsedRows({})
                setLoading(false)
                // setPage(1)

            })
    }

    const validatePropertyTypeFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setPropertyTypeFlexCalculatorError('Please Select Property Type');
        } else {
            setPropertyTypeFlexCalculatorError('');
        }
    };

    const validateLocationFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setLocationSelectedFlexCalculatorError('Please Select Location');
        } else {
            setLocationSelectedFlexCalculatorError('');
        }
    };

    const validatePriceFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setPriceFlexCostCalculatorError('Please Enter Price');
        } else {
            setPriceFlexCostCalculatorError('');
        }
    };

    // ONCLICK FLEX COST CALCULATOR
    const onClickCalculateFlexCost = () => {
        let validate = false
        if (locationSelectedFlexCalculator == '') {
            validate = true
            setLocationSelectedFlexCalculatorError("Please Select Location");
        } else {
            setLocationSelectedFlexCalculatorError('');
        }

        if (priceFlexCostCalculator == '') {
            validate = true
            setPriceFlexCostCalculatorError("Please Enter Price");
        } else {
            setPriceFlexCostCalculatorError('');
        }

        if (propertyTypeSelectedFlexCalculator == '') {
            validate = true
            setPropertyTypeFlexCalculatorError("Please Select Property Type");
        } else {
            setPropertyTypeFlexCalculatorError('');
        }


        if (validate == false) {

            const params = {
                Price: priceFlexCostCalculator == "" ? null : priceFlexCostCalculator,
                CityId: locationSelectedFlexCalculator == "" ? null : locationSelectedFlexCalculator,
                CategoryId: categorySelectedFlexCalculator == "" ? null : categorySelectedFlexCalculator,
                PropertyTypeId: propertyTypeSelectedFlexCalculator == "" ? null : propertyTypeSelectedFlexCalculator,
            }

            dispatch(calculateFlexCostApi(token, params))
                .then(() => {

                })
                .catch(error => {

                })
        }
    }

    // ONCLICK RESET FLEX COST CALCULATOR
    const resetCalculateFlexCost = () => {

        setCategorySelectedFlexCalculator(1)
        setPropertyTypeFlexCalculator("")
        setPriceFlexCostCalculator("")
        setLocationSelectedFlexCalculator("")
        setBathroomsFlexCalculator("")
        setBedroomsFlexCalculator("")
        const params = {

        }

        dispatch(calculateFlexCostApi(token, params))
            .then(() => {

            })
            .catch(error => {

            })


    }

    // LOCATION SEARCH
    const handleLocationSearch = async (inputValue) => {
        if (inputValue.length == 0) {
            setOptions([]); // Clear the options when the input is empty
        }
        else if (inputValue.length > 0) {
            try {
                const response = await axios.post(`${config.baseURL}/Location/BahrainSearch?input=${inputValue}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response?.data?.Data; // Adjust this based on your API response structure


                setOptions(data); // Update the options state with the response data
            } catch (error) {

            }
        }
    };

    const handleSelect = async (event, value) => {

        setLocationSelected(value)
        setStateIdSelected(value?.StateId)
        setCityIdSelected(value?.CityId)
        // You can perform any further actions with the selected value here
    };

    // ONCLICK PROPERTY PUBLISH
    const handlePublishOpen = (item) => {
        setLoading(true)
        setPropertyIdToPublish(item?.Id)
        dispatch(checkPublishCreditsApi(token, item?.Id))
            .then((response) => {
                setLoading(false)
                setPublishOpen(true);

            })
            .catch((error) => {
                setLoading(false)
                setPublishOpen(true);

            });
    };
    const handlePublishClose = () => {
        setPublishOpen(false);
    };

    // ONCLICK UPGRADE PLAN PROPERTY
    const handleUpgradePlanOpen = (item) => {
        setLoading(true)
        setPropertyIdToPublish(item?.Id)
        setSubscriptionIdSelectedFeatured(2)
        setSubscriptionIdSelectedPremium(4)
        setMainSubscriptionTypeSelected("")
        setPropertyCurrentSubscriptionPlanId(item?.SubscriptionPlanId)
        dispatch(checkPublishCreditsApi(token, item?.Id))
            .then((response) => {
                setLoading(false)
                setUpgradePlanOpen(true);
                setFeaturedAutoRenewCheckBox(true)
                setPremiumAutoRenewCheckBox(true)

            })
            .catch((error) => {
                setLoading(false)
                setUpgradePlanOpen(true);

            });
    };
    const handleUpgradePlanClose = () => {

        setUpgradePlanOpen(false);
    };


    // PROPERTY PUBLISH UPGRADE API CALL
    const PublishUpgradeApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        ReferenceNo: referenceNo == "" ? null : referenceNo,
                        OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                        BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                        FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                        PropertyStateId: stateSelected == "" ? null : stateSelected,
                        UserId: userSelected == "" ? null : userSelected,
                        MinBedrooms: bedroomValue[0],
                        MaxBedrooms: bedroomValue[1],
                        PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                        MinPrice: priceValue[0],
                        MaxPrice: priceValue[1],
                        PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                        Status: statusSelected == "" ? null : statusSelected,
                        MinBathroom: bathroomValue[0],
                        MaxBathroom: bathroomValue[1],
                        PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                        Owner: ownerSelected == "" ? null : ownerSelected,
                        UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                        Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                        MinSize: areaValue[0],
                        MaxSize: areaValue[1],
                        CityId: cityIdSelected == "" ? null : cityIdSelected,
                        StateId: stateIdSelected == "" ? null : stateIdSelected,
                        pageNumber: page




                    }
                    dispatch(PropertyFilterListApi(token, params))
                        .then(() => {
                            getCreditBalanceApiCall()
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                        .catch(() => {
                            getCreditBalanceApiCall()
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    getCreditBalanceApiCall()

                    setLoading(false)

                    handleUpgradePlanClose()
                }

            } else {

            }
        } catch (error) {
            toast.error("Property does not exist or is already upgraded to the selected plan", {
                position: 'top-right',
                autoClose: 1300, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
          
            const params = {
                ReferenceNo: referenceNo == "" ? null : referenceNo,
                OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                PropertyStateId: stateSelected == "" ? null : stateSelected,
                UserId: userSelected == "" ? null : userSelected,
                MinBedrooms: bedroomValue[0],
                MaxBedrooms: bedroomValue[1],
                PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                MinPrice: priceValue[0],
                MaxPrice: priceValue[1],
                PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                Status: statusSelected == "" ? null : statusSelected,
                MinBathroom: bathroomValue[0],
                MaxBathroom: bathroomValue[1],
                PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                Owner: ownerSelected == "" ? null : ownerSelected,
                UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                MinSize: areaValue[0],
                MaxSize: areaValue[1],
                CityId: cityIdSelected == "" ? null : cityIdSelected,
                StateId: stateIdSelected == "" ? null : stateIdSelected,
                pageNumber: page




            }
            dispatch(PropertyFilterListApi(token, params))
            .then(() => {
                getCreditBalanceApiCall()
                setLoading(false)
                handleUpgradePlanClose()
            })
            .catch(() => {
                getCreditBalanceApiCall()
                setLoading(false)
                handleUpgradePlanClose()
            })

        }
    };

    // PROPERTY PUBLISH API CALL
    const publishApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        ReferenceNo: referenceNo == "" ? null : referenceNo,
                        OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                        BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                        FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                        PropertyStateId: stateSelected == "" ? null : stateSelected,
                        UserId: userSelected == "" ? null : userSelected,
                        MinBedrooms: bedroomValue[0],
                        MaxBedrooms: bedroomValue[1],
                        PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                        MinPrice: priceValue[0],
                        MaxPrice: priceValue[1],
                        PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                        Status: statusSelected == "" ? null : statusSelected,
                        MinBathroom: bathroomValue[0],
                        MaxBathroom: bathroomValue[1],
                        PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                        Owner: ownerSelected == "" ? null : ownerSelected,
                        UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                        Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                        MinSize: areaValue[0],
                        MaxSize: areaValue[1],
                        CityId: cityIdSelected == "" ? null : cityIdSelected,
                        StateId: stateIdSelected == "" ? null : stateIdSelected,
                        pageNumber: page




                    }
                    dispatch(PropertyFilterListApi(token, params))
                        .then(() => {
                            getCreditBalanceApiCall()
                            setLoading(false)
                            handlePublishClose()
                        })
                        .catch(() => {
                            getCreditBalanceApiCall()
                            setLoading(false)
                            handlePublishClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    getCreditBalanceApiCall()
                    setLoading(false)

                    handlePublishClose()
                }

            } else {
            }
        } catch (error) {
            toast.error("Property does not exist or is already published or has already been requested for approval to publish", {
                position: 'top-right',
                autoClose: 1300, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
          
            const params = {
                ReferenceNo: referenceNo == "" ? null : referenceNo,
                OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                PropertyStateId: stateSelected == "" ? null : stateSelected,
                UserId: userSelected == "" ? null : userSelected,
                MinBedrooms: bedroomValue[0],
                MaxBedrooms: bedroomValue[1],
                PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                MinPrice: priceValue[0],
                MaxPrice: priceValue[1],
                PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                Status: statusSelected == "" ? null : statusSelected,
                MinBathroom: bathroomValue[0],
                MaxBathroom: bathroomValue[1],
                PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                Owner: ownerSelected == "" ? null : ownerSelected,
                UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                MinSize: areaValue[0],
                MaxSize: areaValue[1],
                CityId: cityIdSelected == "" ? null : cityIdSelected,
                StateId: stateIdSelected == "" ? null : stateIdSelected,
                pageNumber: page




            }
            dispatch(PropertyFilterListApi(token, params))
                .then(() => {
                    getCreditBalanceApiCall()
                    setLoading(false)
                    handlePublishClose()
                })
                .catch(() => {
                    getCreditBalanceApiCall()
                    setLoading(false)
                    handlePublishClose()
                })

        }
    };

    // ONCLICK SELECT YOUR PLAN
    const onClickPublishUpgrade = () => {
        if (mainSubscriptionTypeSelected == 1) {
            if (subscriptionIdSelectedFeatured == 2) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else if (subscriptionIdSelectedFeatured == 3) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }

            }
        }
        else if (mainSubscriptionTypeSelected == 2) {
            if (subscriptionIdSelectedPremium == 4) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForPremium_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox

                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else if (subscriptionIdSelectedPremium == 5) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsPremium_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox

                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const onClickPublish = () => {
        if (checkPublishCreditsData?.IsSuffiecientCreditsForStandard == 1) {
            const params = {
                "PropertyId": propertyIdToPublish,
                "SubscriptionPlanId": 1
            }
            publishApiCall(params)
        }
        else {
            toast.error("Insufficient Balance", {
                position: 'top-right',
                autoClose: 1000, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const handleChangeAutoRenew = async (itemId) => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Property/AutoRenew?PropertyId=${itemId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                const params = {
                    pageNumber: page,
                    ReferenceNo: referenceNo == "" ? null : referenceNo,
                    OfferingType: offeringTypeSelected == "" ? null : offeringTypeSelected,
                    BoostingProducts: boostingProductsSelected == '' ? null : boostingProductsSelected,
                    FurnishingType: furnishingTypeSelected == "" ? null : furnishingTypeSelected,
                    PropertyStateId: stateSelected == "" ? null : stateSelected,
                    UserId: userSelected == "" ? null : userSelected,
                    MinBedrooms: bedroomValue[0],
                    MaxBedrooms: bedroomValue[1],
                    PropertyCategoty: propertyCategorySelected == "" ? null : propertyCategorySelected,
                    MinPrice: priceValue[0],
                    MaxPrice: priceValue[1],
                    PropertyStatus: propertyStatusSelected == "" ? null : propertyStatusSelected,
                    Status: statusSelected == "" ? null : statusSelected,
                    MinBathroom: bathroomValue[0],
                    MaxBathroom: bathroomValue[1],
                    PropertyType: propertyTypeSelected == "" ? null : propertyTypeSelected,
                    Owner: ownerSelected == "" ? null : ownerSelected,
                    UnitNo: unitNoSelected == "" ? null : unitNoSelected,
                    Amenities: selectAminitiesSelected.length == 0 ? null : selectAminitiesSelected,
                    MinSize: areaValue[0],
                    MaxSize: areaValue[1],
                    CityId: cityIdSelected == "" ? null : cityIdSelected,
                    StateId: stateIdSelected == "" ? null : stateIdSelected

                }
                dispatch(PropertyFilterListApi(token, params))
                    .then((response) => {
                        setPage(page)
                        setLoading(false)
                        setpropertyPopupOpen(false);
                    })
                    .catch((error) => {
                        setPage(page)
                        setLoading(false)


                    });


            } else {

            }
        } catch (error) {


        }
    };

    const onClickPdfDownload = (propertyId) => {
        const url = `${config.baseURL}/Reports/PdfBrochure?id=${propertyId}`;
        window.open(url, '_blank');


    }

    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={propertyFilterLoad}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={propertyPopupOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosepopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClosepopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        <p>Are you sure you want to delete this property ?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClosepopup}>Cancel</Button>
                    <Button onClick={() => onClickDeleteProperty()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={unPublishModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseUnpublishpopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Unpublish</h6>
                        <Button onClick={handleCloseUnpublishpopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        <p>Are you sure you want to unpublish this property ?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleCloseUnpublishpopup}>Cancel</Button>
                    <Button onClick={() => onClickUnpunlishProperty()}>Unpublish</Button>
                </DialogActions>
            </Dialog>
            <div class="overlay"></div>
            <Topbar />
            <Sidebar message={'Properties'} />

            <section class="content">
                <div class="block-header">
                    <h2>{t('properties')}</h2>

                </div>

                <div class="page_heading">
                    <h3>{t('propertiesList')}</h3>
                    <a onClick={() => onClickPropertyCreate()}><img src={plus} /></a>
                </div>

                <div class="main_class">
                    <div class="form_block">
                        <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" value={referenceNo} title={t('referenceNo')} placeholder={t('referenceNo')} onChange={(e) => setreferenceNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            sx={{ width: 300, fontSize: 14, }}
                                            className='form-control auto_complete'
                                            onChange={handleSelect}
                                            value={locationSelected}
                                            title='Location'
                                            options={options}
                                            getOptionLabel={(option) => option?.Name} // Adjust this based on your API response structure
                                            onInputChange={(event, newInputValue) => handleLocationSearch(newInputValue)}
                                            renderInput={(params) => <TextField {...params} placeholder={"Location"}

                                            />}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            displayEmpty
                                            value={offeringTypeSelected}
                                            onChange={(e) => { setOfferingTypeSelected(e.target.value); setPropertyTypeSelected("") }}
                                            input={<OutlinedInput />}
                                            title={t('offeringType')}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('offeringType')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.OfferingTypes?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            title={t('boostingProducts')}
                                            displayEmpty
                                            value={boostingProductsSelected}
                                            onChange={(e) => setBoostingProductsSelected(e.target.value)}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('boostingProducts')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.BoostingProducts?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            title={t('furnishingType')}
                                            displayEmpty
                                            value={furnishingTypeSelected}
                                            onChange={(e) => setFurnishingTypeSelected(e.target.value)}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('furnishingType')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {

                                userRole == 'AgentAdmin' && (
                                    <div class="col-md-3 col-sm-4 col-xs-6">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    title={t('user')}
                                                    displayEmpty
                                                    value={userSelected}
                                                    onChange={(e) => setUserSelected(e.target.value)}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('user')}</em>
                                                    </MenuItem>
                                                    {propertyMasterData?.Users?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                )
                            }

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="range_slider_block">
                                    <h6>Bedrooms</h6>
                                    <Slider
                                        range
                                        value={bedroomValue}
                                        onChange={bedroomHandleChange}
                                        min={-1}
                                        max={20}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}

                                    />
                                    <span class='left'>{bedroomValue[0] === -1 ? "Studio" : bedroomValue[0]}</span>
                                    <span class='right'>{bedroomValue[1] === -1 ? "Studio" : bedroomValue[1]} </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            title={t('propertyCategory')}
                                            displayEmpty
                                            value={propertyCategorySelected}
                                            onChange={(e) => { setPropertyCategorySelected(e.target.value); setPropertyTypeSelected("") }}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('propertyCategory')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.Categories?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="range_slider_block">
                                    <h6>Price</h6>

                                    <Slider
                                        range
                                        value={priceValue}
                                        onChange={priceHandleChange}
                                        min={0}
                                        max={9999999}
                                        allowCross={false}

                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}
                                    />
                                    <span class='left'>BHD {priceValue[0]}</span>
                                    <span class='right'>BHD {priceValue[1]}</span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            title={t('propertyStatus')}
                                            displayEmpty
                                            value={propertyStatusSelected}
                                            onChange={(e) => setPropertyStatusSelected(e.target.value)}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('propertyStatus')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.PropertyStatuses?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="range_slider_block">
                                    <h6>Bathrooms</h6>

                                    <Slider
                                        range
                                        value={bathroomValue}
                                        onChange={bathroomHandleChange}
                                        min={0}
                                        max={20}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}
                                    />
                                    <span class='left'>{bathroomValue[0]}</span>
                                    <span class='right'>{bathroomValue[1]}</span>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            disabled={offeringTypeSelected == "" || propertyCategorySelected == ""}
                                            displayEmpty
                                            title={t('propertyType')}
                                            value={propertyTypeSelected}
                                            onChange={(e) => setPropertyTypeSelected(e.target.value)}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('propertyType')}</em>
                                            </MenuItem>
                                            {selectedPropertyTypeDropdown?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            title={t('owner')}
                                            displayEmpty
                                            value={ownerSelected}
                                            onChange={(e) => setOwnerSelected(e.target.value)}
                                            input={<OutlinedInput />}

                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('owner')}</em>
                                            </MenuItem>
                                            {propertyMasterData?.Owners?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="range_slider_block">
                                    <h6>Area(sqm)</h6>

                                    <Slider
                                        range
                                        value={areaValue}
                                        onChange={areaHandleChange}
                                        min={0}
                                        max={999999}
                                        allowCross={false}
                                        handleStyle={[handleStyle, handleStyle]}
                                        trackStyle={[trackStyle]}
                                    />
                                    <span class='left'>{areaValue[0]} Sqm</span>
                                    <span class='right'>{areaValue[1]} Sqm</span>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            sx={{ borderColor: 'transparent' }}
                                            multiple
                                            displayEmpty
                                            title='Select Amenities'
                                            value={selectAminitiesSelected}
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <em className='select_text'>Select Amenities</em>;
                                                }

                                                const selectedNames = selected.map(id => {
                                                    const amenity = propertyMasterData?.Amenities.find(item => item?.Id === id);
                                                    return amenity ? amenity.Name : ''; // Return the name if found, otherwise an empty string
                                                });

                                                return selectedNames.join(', ');
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' disabled value="">
                                                <em className='placeholder'>Select Amenities</em>
                                            </MenuItem>
                                            {propertyMasterData?.Amenities?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12 selected_amenities">
                                <ul>
                                    {
                                        propertyMasterData?.Amenities?.map((item) => {
                                            if (selectAminitiesSelected.includes(item.Id)) {
                                                return (
                                                    <>
                                                        <li>{item?.Name}</li>

                                                    </>
                                                )
                                            } else {

                                                return;
                                            }

                                        })
                                    }

                                </ul>
                            </div>

                        </div>
                        <div class="button_block">
                            <a href="#" class="links" onClick={() => resetPropertyFilter()}>Reset</a>
                            <a href="#" class="links" onClick={() => handleSearch()}>{t('search')}</a>
                        </div>
                    </div>
                </div>

                <div class="main_class">
                    <div class="credit_balance_block">
                        <div class="balance_block">
                            <p class="balanced">{t('creditBalance')}:&nbsp;&nbsp;<span>{getCreditBalance}</span><a onClick={() => getCreditBalanceApiCall()} class={isRefreshing ? "animated_refresh" : "static_refresh"}><img src={refresh}></img></a></p>

                            <button onClick={handleOpen}><img src={calculator} /></button>
                        </div>
                    </div>
                    <div class="table_block">
                        <div class="card">
                            <div class="table-responsive">
                                {
                                    propertyFilterData?.Data?.length != 0 ?
                                        <table class="table table-bordered table-striped table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th></th>

                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('refno')}</h5>
                                                            <p>{t('user')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('title')}</h5>
                                                            <p>{t('location')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('layout')}</h5>
                                                            <p>{t('Size')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('type')}</h5>
                                                            <p>{t('offering')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('price')}</h5>
                                                            <p>{t('category')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('Updated')}</h5>
                                                            <p>{t('Listed')}</p>
                                                        </div>
                                                    </th>

                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('listing')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>Auto-renew</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('qlt')}.<br />{t('score')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>Pdf Brochure</h5>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    propertyFilterData?.Data?.map((item) => {


                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <div class="table_detail">
                                                                            <a href="javascript:void(0)" class="link blue" onClick={() => toggleRowCollapse(item.Id)}><img src={info} /></a>
                                                                            <a onClick={() => onClickPropertyEdit(item?.Id)} class="link green"><img src={edit} /></a>
                                                                            <a href="#" onClick={() => handleClickOpen(item?.Id)} class="link red"><img src={deleteIcon} /></a>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail">
                                                                            <h6>{item?.Reference}</h6>
                                                                            <p>{item?.AssingnedName}</p>

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail name">
                                                                            <Tooltip title={item?.Title} arrow className="tooltip_notes">
                                                                                <h6 style={{ textOverflow: 'ellipsis', maxWidth: '120px', whiteSpace: 'nowrap', overflow: "hidden" }}>{item?.Title}</h6>
                                                                            </Tooltip>
                                                                            <Tooltip title={item?.LocationName} arrow className="tooltip_notes">
                                                                                <p style={{ textOverflow: 'ellipsis', maxWidth: '120px', whiteSpace: 'nowrap', overflow: "hidden" }}>{item?.LocationName}</p>
                                                                            </Tooltip>

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-group">
                                                                            <div class="table_detail">
                                                                                <h6>
                                                                                    <div class="props">{item?.Noofbedrooms == -1 ? "Studio" : item?.Noofbedrooms}<img src={bed} /></div>
                                                                                    <div class="props">{item?.Noofbathrooms}<img src={bathtub} /></div>
                                                                                </h6>

                                                                                <Tooltip title={<Typography component="div">
                                                                                    {item?.Size} sqm
                                                                                </Typography>}
                                                                                    arrow className="tooltip_notes">
                                                                                    <p><div class="props">{item?.Size} sqm<img src={area_icon} /></div></p>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail">
                                                                            <h6>{item?.PropertyTypeName}</h6>
                                                                            <p>{item?.OfferingTypeName}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail">

                                                                            <Tooltip title={<Typography component="div">

                                                                                BHD {item?.MonthlyPrice}{item?.OfferingTypeName == "Rent" ? '/M' : null}
                                                                            </Typography>}
                                                                                arrow className="tooltip_notes">
                                                                                {item?.OfferingTypeName == "Rent" ?

                                                                                    <h6>BHD {item?.MonthlyPrice}/M</h6>
                                                                                    :
                                                                                    <h6>BHD {item?.MonthlyPrice}</h6>

                                                                                }
                                                                            </Tooltip>
                                                                            <p>{item?.CategoryName}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail">

                                                                            <Tooltip title={item?.UpdatedStr} arrow className="tooltip_notes">
                                                                                <h6>{item?.UpdatedStr}</h6>
                                                                            </Tooltip>
                                                                            <Tooltip title={item?.CreatedStr} arrow className="tooltip_notes">
                                                                                <p>{item?.CreatedStr}</p>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="table_detail">
                                                                            {
                                                                                item?.IsApplied ?
                                                                                    <h6>Requested to Publish</h6>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            item?.SubscriptionPlanId != null && (
                                                                                                <>
                                                                                                    <h6>{item?.SubscriptionPlanId == 1 ? "Standard" : item?.SubscriptionPlanId == 2 ? "Featured" : item?.SubscriptionPlanId == 3 ? "Featured" : item?.SubscriptionPlanId == 4 ? "Premium" : "Premium"}</h6>

                                                                                                </>

                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            item?.SubscriptionPlanId != 4 && item?.SubscriptionPlanId != 5 && (
                                                                                                <div style={{ display: 'block' }}>
                                                                                                    <a href='#' onClick={() => item?.SubscriptionPlanId == null ? handlePublishOpen(item) : handleUpgradePlanOpen(item)} class={item?.SubscriptionPlanId == null ? "status green" : "status blue"}>{item?.SubscriptionPlanId == null ? "Publish" : "Upgrade"}</a>
                                                                                                </div>

                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            item?.SubscriptionPlanId != null && (
                                                                                                <div style={{ display: 'block' }}>

                                                                                                    <a onClick={() => handleOpenUnpublishpopup(item?.Id)} class={"status red"} style={{ marginTop: 10 }}>{"Unpublish"}</a>
                                                                                                </div>

                                                                                            )
                                                                                        }
                                                                                    </>
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <Switch {...label}
                                                                            className='switch_bar'
                                                                            checked={item?.SubscriptionPlanId == null ? false : item?.SubscriptionPlanId == 1 ? item?.IsAutoRenew : item?.IsBoosterAutoRenew}
                                                                            disabled={item?.SubscriptionPlanId == null ? true : (item?.SubscriptionPlanId == 1 && mimicFlag == false) ? true : false}
                                                                            onChange={() => handleChangeAutoRenew(item?.Id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail center">
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: item?.QualityGrade == 'Emerald(E)' ? '#FDB51F' : '#193057' }} class="progress_bar"><span style={{ color: item?.QualityGrade == 'Emerald(E)' ? '#FDB51F' : '#193057' }}>{item?.QualityGrade == 'Emerald(E)' ? 'E' : 'S'}</span></div>
                                                                            {item?.QualityScore ?

                                                                                <span class="opac" style={{ marginLeft: 8 }}>{item?.QualityScore}%</span> :
                                                                                <span class="opac" style={{ marginLeft: 8 }}>0%</span>
                                                                            }
                                                                            <div class="quality_score_detail">
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('title')}:</h6>
                                                                                    <p>{item?.TitleScore}%</p>
                                                                                </div>
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('description')}:</h6>
                                                                                    <p>{item?.DescriptionScore}%</p>
                                                                                </div>
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('images')}:</h6>
                                                                                    <p>{item?.ImageCountScore}%</p>
                                                                                </div>
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('duplicatePhotos')}:</h6>
                                                                                    <p>{item?.DuplicateImageScore}%</p>
                                                                                </div>
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('photoSizes')}:</h6>
                                                                                    <p>{item?.ImageSizeScore}%</p>
                                                                                </div>
                                                                                <div class="quality_tag">
                                                                                    <h6>{t('ImageDiversity')}:</h6>
                                                                                    <p>{item?.ImageDiversity1Score}%</p>
                                                                                </div>

                                                                                <div class="quality_tag">
                                                                                    <h6>{t('additionalFields')}:</h6>
                                                                                    <p>{item?.NonMandatoryFieldsScore}%</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="table_detail text-center">
                                                                            <a onClick={() => onClickPdfDownload(item?.Id)}><img style={{ maxWidth: '30px' }} src={pdf_icon} /></a>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    collapsedRows[item.Id] &&
                                                                    <tr>
                                                                        <td colSpan={11}>
                                                                            <table>
                                                                                <div class="props_detail">
                                                                                    <div class="row clearfix d-flex">
                                                                                        <div class="col-md-4">
                                                                                            {
                                                                                                item?.MediaUrlList.length != 0 ?

                                                                                                    <div class="property_list_image" >
                                                                                                        <Carousel images={item?.MediaUrlList} />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div class="property_list_image">
                                                                                                        <img src={require("../../images/no_image.jpg")} />
                                                                                                    </div>

                                                                                            }
                                                                                        </div>
                                                                                        <div class="col-md-8 align-self-center">
                                                                                            <div class="property_list_detail">
                                                                                                <h2>{item?.Title}</h2>
                                                                                                <div class="amenities_details">
                                                                                                    <p class="status green">{item?.AvailabilitytypeName}</p>
                                                                                                    {
                                                                                                        item?.AvailableFrom != null && (
                                                                                                            <div class="amenities_block">
                                                                                                                <img src={dateRange}></img>

                                                                                                                <span>From {formatDate(item?.AvailableFrom)}</span>


                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                    <div class="amenities_block">
                                                                                                        <img src={sofa}></img>
                                                                                                        <span>{item?.FurnishingTypeName}</span>
                                                                                                    </div>
                                                                                                    <div class="amenities_block">
                                                                                                        <img src={dollar}></img>
                                                                                                        <span>{item?.Price}</span>
                                                                                                    </div>
                                                                                                    <div class="amenities_block">
                                                                                                        <img src={bicycle}></img>
                                                                                                        <span>{item?.Noofparking} Parking</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="quality_score_block">
                                                                                                    <div class="amenities_detail_block">
                                                                                                        <div class="services_detail">


                                                                                                            <p>Owner:&nbsp;&nbsp;&nbsp;<span>{item?.OwnerName}</span></p>

                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {
                                                                                                                item?.AmenitiesList?.map((item) => {
                                                                                                                    return (
                                                                                                                        <p class="status green">{item}</p>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>


                                                                                                    </div>
                                                                                                    <div class="quality_block">
                                                                                                        <h3>Quality Score</h3>
                                                                                                        <p>Quality Score Of This Listing</p>
                                                                                                        <div class="services_detail">
                                                                                                            <p>Title:&nbsp;&nbsp;&nbsp;<span>{item?.TitleScore}</span></p>
                                                                                                            <p>Description:&nbsp;&nbsp;&nbsp;<span>{item?.DescriptionScore}</span></p>
                                                                                                            <p>Images:&nbsp;&nbsp;&nbsp;<span>{item?.ImageCountScore}</span></p>
                                                                                                            <p>Duplicate Photos:&nbsp;&nbsp;&nbsp;<span>{item?.DuplicateImageScore}</span></p>
                                                                                                            <p>Photo Sizes:&nbsp;&nbsp;&nbsp;<span>{item?.ImageSizeScore}</span></p>
                                                                                                            <p>Image Diversity:&nbsp;&nbsp;&nbsp;<span>{item?.ImageDiversity1Score}</span></p>

                                                                                                            <p>Additional Fields:&nbsp;&nbsp;&nbsp;<span>{item?.NonMandatoryFieldsScore}</span></p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </table>
                                                                        </td>
                                                                    </tr>

                                                                }
                                                            </>

                                                        )
                                                    })
                                                }






                                            </tbody>
                                        </table> : <h1 class="text-center">No Data Found</h1>

                                }

                            </div>
                            <div class="text-right">
                                <Pagination className="pagination" count={propertyFilterData?.TotalPages} page={page} onChange={pageHandleChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Flex Cost Calculator</h2>
                            <button onClick={handleClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <p>Fill out your property details and calculate how many Flex credits you will need to list or upgrade it.</p>
                            <div class="form_block">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={locationSelectedFlexCalculator}
                                                    onChange={(e) => { setLocationSelectedFlexCalculator(e.target.value); validateLocationFlexCostCalculator(e.target.value) }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('location')}</em>
                                                    </MenuItem>
                                                    {propertyMasterData?.FlexCalLocations?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    locationSelectedFlexCalculatorError && (
                                                        <span class="validation_notes">{locationSelectedFlexCalculatorError}</span>

                                                    )
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={categorySelectedFlexCalculator}
                                                    onChange={(e) => { setCategorySelectedFlexCalculator(e.target.value); setPropertyTypeFlexCalculator("") }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>Category</em>
                                                    </MenuItem>
                                                    {propertyMasterData?.CategoryForOfferingTypes?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={propertyTypeSelectedFlexCalculator}
                                                    onChange={(e) => { setPropertyTypeFlexCalculator(e.target.value); validatePropertyTypeFlexCostCalculator(e.target.value) }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>Property Type</em>
                                                    </MenuItem>
                                                    {selectedPropertyTypeDropdownFlexCalculator?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    propertyTypeSelectedFlexCalculatorError && (
                                                        <span class="validation_notes">{propertyTypeSelectedFlexCalculatorError}</span>

                                                    )
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    displayEmpty
                                                    disabled={categorySelectedFlexCalculator == 3 || categorySelectedFlexCalculator == 4}
                                                    value={bedroomsFlexCalculator}
                                                    onChange={(e) => setBedroomsFlexCalculator(e.target.value)}
                                                    input={<OutlinedInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('bedrooms')}</em>
                                                    </MenuItem>
                                                    {numbers?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    displayEmpty
                                                    value={bathroomsroomsFlexCalculator}
                                                    onChange={(e) => setBathroomsFlexCalculator(e.target.value)}
                                                    input={<OutlinedInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('bathroom')}</em>
                                                    </MenuItem>
                                                    {numbers?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <input type="text" maxLength={7} value={priceFlexCostCalculator} onKeyPress={handleKeyPress} onChange={(e) => { setPriceFlexCostCalculator(e.target.value); validatePriceFlexCostCalculator(e.target.value) }} class="form-control" placeholder="Price" />
                                                {
                                                    priceFlexCostCalculatorError && (
                                                        <span class="validation_notes">{priceFlexCostCalculatorError}</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        <Select
                                                            className='form-control border_temp'

                                                            displayEmpty
                                                            value={categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? priceTypeSelectedFlexCostRent : priceTypeSelectedFlexCostSale}
                                                            onChange={(e) => categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? setPriceTypeSelectedFlexCostRent(e.target.value) : setPriceTypeSelectedFlexCostSale(e.target.value)}
                                                            input={<OutlinedInput />}

                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                        width: 250,
                                                                    },
                                                                },
                                                                disableScrollLock: true,
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem className='drop_menus' value="">
                                                                <em className='select_text'></em>
                                                            </MenuItem>
                                                            {priceOptionsDisplay?.map((item) => (
                                                                <MenuItem
                                                                    className='drop_menus'
                                                                    key={item?.Id}
                                                                    value={item?.Id}
                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                >
                                                                    {item?.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>Listing Cost (Credits)</h4>
                            <div class="plans_detail">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Standard</h6>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Standard == null ? "--" : flexCostData?.Data?.Standard}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Featured</h6>
                                            <p>15 Days:&nbsp;&nbsp;{flexCostData?.Data?.Featured_for_15_Days == null ? "--" : flexCostData?.Data?.Featured_for_15_Days}</p>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Featured_for_1_Month == null ? "--" : flexCostData?.Data?.Featured_for_1_Month}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Premium</h6>
                                            <p>15 Days:&nbsp;&nbsp;{flexCostData?.Data?.Premium_for_15_Days == null ? "--" : flexCostData?.Data?.Premium_for_15_Days}</p>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Premium_for_1_Month == null ? "--" : flexCostData?.Data?.Premium_for_1_Month}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" onClick={() => resetCalculateFlexCost()} class="links">Reset</a>
                                <a href="#" onClick={() => onClickCalculateFlexCost()} class="links">Calculate</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={upgradePlanOpen}
                    onClose={handleUpgradePlanClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Boost on Yspace.ai</h2>
                            <button onClick={handleUpgradePlanClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <div class="credit_numbers">
                                <p>Select the type of boost you want to give to your listing.</p>
                                <div class="avail_credits">
                                    <p class="blue_text"><img src={require("../../images/logo_icon.png")} />Available Credits: <span>{checkPublishCreditsData?.AvailableCredits}</span></p>
                                </div>
                            </div>
                            <div class="upgrade_plan_block">
                                {
                                    propertyCurrentSubscriptionPlanId == 1 && (
                                        <div class={mainSubscriptionTypeSelected == 1 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(1)}>
                                            <h5>Featured</h5>
                                            <div class="duration_block">
                                                <p>Duration</p>
                                                <div class="form_block">
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="15 days"
                                                            name="radio-buttons-group"
                                                            class="radio_button_block"

                                                        >
                                                            <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedFeatured(2)} />

                                                            <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedFeatured(3)} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div class="table_heading">
                                                <div style={{ display: 'inline-block' }} >
                                                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={featuredAutoRenewCheckBox} onChange={handleFeaturedAutoRenewCheckBox} />} label="Auto-renew" />
                                                </div>

                                            </div>
                                            <div class="upgrade_credit_block">
                                                <h6>{subscriptionIdSelectedFeatured == 2 ? checkPublishCreditsData?.DeductionCreditsForFeatured_15_Days : checkPublishCreditsData?.DeductionCreditsForFeatured_1_Month} Credits</h6>

                                            </div>

                                        </div>
                                    )
                                }
                                <div class={mainSubscriptionTypeSelected == 2 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(2)}>
                                    <h5>Premium</h5>
                                    <div class="duration_block">
                                        <p>Duration</p>
                                        <div class="form_block">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="15 days"
                                                    name="radio-buttons-group"
                                                    class="radio_button_block"
                                                >
                                                    <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedPremium(4)} />

                                                    <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedPremium(5)} />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="table_heading">
                                        <div style={{ display: 'inline-block' }} >
                                            <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={premiumAutoRenewCheckBox} onChange={handlePremiumAutoRenewCheckBox} />} label="Auto-renew" />
                                        </div>

                                    </div>
                                    <div class="upgrade_credit_block">
                                        <h6>{subscriptionIdSelectedPremium == 4 ? checkPublishCreditsData?.DeductionCreditsForPremium_15_Days : checkPublishCreditsData?.DeductionCreditsForPremium_1_Month} Credits</h6>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" onClick={() => handleUpgradePlanClose()} style={{
                                    color: loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                }} >Cancel</a>
                                <a href="#" class="links" onClick={() => onClickPublishUpgrade()} style={{
                                    color: mainSubscriptionTypeSelected == "" || loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: mainSubscriptionTypeSelected == "" || loading ? 'none' : "auto", // Disable pointer events when disabled

                                }}>Apply</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={publishOpen}
                    onClose={handlePublishClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Publish Properties</h2>
                            <button onClick={handlePublishClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <div class="publish_properties_block">
                                <img style={{ height: 85, marginLeft: -10 }} src={require("../../images/logo.png")} />
                                {
                                    checkPublishCreditsData?.Status == "true" ?
                                        <>
                                            <p><span class="red_text">{checkPublishCreditsData?.DeductionCreditForStandard} credits</span> will be deducted from your balance.</p>
                                            <h6>Available Credits : {checkPublishCreditsData?.AvailableCredits}</h6>
                                        </>
                                        :
                                        <p><span class="red_text">This property cannot be published as it may have incomplete information to publish to portals.</span></p>
                                }

                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" style={{
                                    color: loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                }} onClick={() => handlePublishClose()}>Cancel</a>
                                {
                                    checkPublishCreditsData?.Status == "true" && (
                                        <a href="#" style={{
                                            color: loading ? '#999' : "inherit",// Change color when disabled
                                            pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                        }} class="links" onClick={() => onClickPublish()}>Publish</a>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </Modal>
            </section >
            <ToastContainer />

        </div >
    );

}



