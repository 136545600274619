import React, { useState } from "react";
import person from '../../images/person.svg';
import lock from '../../images/security.svg';
import unlock from '../../images/unlock.svg';
import roundArrow from '../../images/round-arrow.svg';
import { useNavigate } from "react-router-dom";

// Bootstrap Core Css
import '../../plugins/bootstrap/css/bootstrap.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginApi } from "../../redux/ApiActionCreator";

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // States for form handling and error messages
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordShow, setpasswordShow] = useState(false);

    // Handle Snackbar open
    const handleClick = () => {
        setOpen(true);
    };

    // Handle Snackbar close
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    // Username validation
    const validateUsername = (value) => {
        if (value.length === 0) {
            setUsernameError(t('usernameRequired'));
        } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            setUsernameError('Enter valid email');
        } else {
            setUsernameError('');
        }
    };

    // Password validation
    const validatePassword = (value) => {
        if (value.length === 0) {
            setPasswordError(t('passwordRequired'));
        } else if (value.length < 6) {
            setPasswordError(t('passwordMinimumChar'));
        } else {
            setPasswordError('');
        }
    };

    // Handle username change
    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsername(value);
        validateUsername(value);
    };

    // Handle password change
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        validatePassword(value);
    };

    // Handle login action
    const handleLogin = () => {
        let validate = false;

        if (username === '') {
            validate = true;
            setUsernameError(t('usernameRequired'));
        } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(username)) {
            validate = true;
            setUsernameError('Enter valid email');
        } else {
            setUsernameError('');
        }

        if (password === '') {
            validate = true;
            setPasswordError('Password is required');
        } else {
            setPasswordError('');
        }

        if (!validate) {
            setLoading(true);
            const params = { email: username, password: password };
            dispatch(loginApi({ params }))
                .then(() => {
                    setLoading(false);
                    navigate('/Dashboard');
                })
                .catch((error) => {
                    handleClick();
                    setLoading(false);
                    console.log(error);
                });
        }
    };

    // Handle Enter key press for login
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    return (
        <div>
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <form onSubmit={(e) => e.preventDefault()} onKeyDown={handleKeyDown}>
                    <div className="login_block">
                        <div className="back_block">
                            <div className="arrow_block"></div>
                            <div className="login_title_block">
                                <figure><img style={{ height: 85, marginLeft: -10 }} src={require("../../images/logo.png")} alt="Logo" /></figure>
                                <h1>{t('signIn')}</h1>
                                <p>{t('secureCommunication')}</p>
                                <div className="form_block">
                                    <div className="input-group">
                                        <span className="input-group-addon"><img src={person} alt="Person" /></span>
                                        <input type="text" className="form-control" placeholder={t('enterUsername')} value={username} onChange={handleUsernameChange} />
                                        {usernameError && <span className="validation_notes">{usernameError}</span>}
                                    </div>

                                    <div className="input-group icon_field">
                                        <span className="input-group-addon">
                                            <a onClick={() => setpasswordShow(!passwordShow)}>
                                                <img src={passwordShow ? unlock : lock} alt="Lock" />
                                            </a>
                                        </span>
                                        <input type={passwordShow ? 'text' : 'password'} className="form-control" placeholder={t('enterPassword')} value={password} onChange={handlePasswordChange} />
                                        {passwordError && <span className="validation_notes">{passwordError}</span>}
                                    </div>
                                    <a onClick={() => navigate('/ForgotPassword')}><p className="text-right" style={{ marginTop: 0 }}>Forgot Password?</p></a>
                                    <div className="login_button_block">
                                        <button type="submit" onClick={handleLogin} className="btn btn-primary waves-effect">
                                            {t('signIn')}<img src={roundArrow} alt="Arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontSize: '1.7rem' }}>
                            Invalid Credentials
                        </Alert>
                    </Snackbar>
                </form>
            )}
        </div>
    );
};

export default Login;
